import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Button, ButtonGroup } from 'react-bootstrap';
import { useLocation, useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { fetchAlarms } from '../actions';
import Table from '../../../components/Table';
import TableToolbar from '../../../components/TableToolbar';

import {
  siteName,
  siteSubareaName,
  equipmentName,
  equipmentNumber,
} from '../../equipment/columns';
import {
  eventTimeStamp,
  eventTrigger,
  alarmCount,
  current,
  alarmStatusExtra as statusBadge,
  eventTypeExtra as eventTypeBadge,
  downtimeSavedHours,
} from '../columns';
import useOrganisationUserColumns from '../../organisation/hooks/useOrganisationUserColumns';
import { getAlarms, getAlarmListState, getAlarmsShowTags } from '../selectors';
import {
  getActiveSubGroupId,
} from '../../organisation/selectors';
import { fetchDevices } from '../../equipment/actions';
import { usePoll } from '../../../hooks/usePoll';
import { getIntervalMilliSeconds } from '../../../components/lib/utils';
import useIncludeSubGroup from '../../../hooks/useIncludeSubGroup';
import { ExpansionIndicator } from '../../../components/table/formatters';
import { columnWithFixedWidth } from '../../../components/table/utils';
import DownloadTableCSVLink from '../../../components/DownloadTableCSVLink';
import AlarmDateFilter, { DATE_RANGE } from '../components/AlarmDateFilter';
import '../AlarmsList.scss';
import ColumnsManager, { reorderColumns } from '../../../components/ColumnsManager';
import useLocalPreference from '../../user/hooks/useLocalPreference';
import { NoDataIndication } from './Alarms';
import useLocalisedColumns from '../../../hooks/useLocalisedColumns';

const { REACT_APP_CHECK_ORGANISATION_INTERVAL_MINUTES = 5 } = process.env;
const checkPeriods = getIntervalMilliSeconds(REACT_APP_CHECK_ORGANISATION_INTERVAL_MINUTES);

const defaultSorted = [{
  dataField: 'alarm_timestamp',
  order: 'desc'
}];

const alarmTypeFilter = 'condition';

export function useTableProps({
  alarmTypeFilter,
  activeSubGroupId,
  alarms=[],
  fetchAlarms,
  fetchDevices,
  loading,
  lastFetch,
  error,
  alarmStatus,
}) {
  const { t } = useTranslation();
  const { includeSubGroup, setIncludeSubGroup } = useIncludeSubGroup('events');

  const tableName = 'event_list';
  const { columns: organisationUserColumns } = useOrganisationUserColumns({ showTag: tableName });
  const organisationUserColumnsWithWidth = organisationUserColumns.map(col => ([col, 100]));

  const defaultColumns = [
    [eventTimeStamp, 150],
    [current, 70],
    [eventTypeBadge, 150],
    [siteName, 150],
    [siteSubareaName, 130],
    [equipmentName, 250],
    [equipmentNumber, 190],
    ...organisationUserColumnsWithWidth,  // For now, we presume put the user columns after equipment name.
    [statusBadge, 150],
    [alarmCount, 100],
    [eventTrigger, 300],
    [downtimeSavedHours, 190],
  ];

  usePoll(
    canceller => fetchAlarms({ view: 'event' }, canceller),
    [fetchAlarms, alarmTypeFilter],
    { cancellable: true, interval: checkPeriods }
  );

  usePoll(
    () => activeSubGroupId && fetchDevices({ includeChildren: includeSubGroup }),
    [fetchDevices, activeSubGroupId, includeSubGroup],
    { interval: checkPeriods, includeSubGroup }
  );

  const [dateRange, setDateRange] = useState(DATE_RANGE.ALL);
  const cutoffDate = useMemo(() => {
    let cutoffDate;
    switch(dateRange) {
      case DATE_RANGE.LAST_WEEK: cutoffDate = moment().subtract(1, 'weeks').startOf('day'); break;
      case DATE_RANGE.LAST_MONTH: cutoffDate = moment().subtract(1, 'months').startOf('day'); break;
      case DATE_RANGE.LAST_YEAR: cutoffDate = moment().subtract(1, 'years').startOf('day'); break;
      default: cutoffDate = moment('1970-01-01');
    }
    return cutoffDate;
  }, [dateRange]);

  const filteredAlarms = useMemo(() => {
    return alarms.filter(({ alarm_status }) => {
      const statusList = alarmStatus?.split(',').map(v => v.toLowerCase());
      return statusList ? statusList.includes(alarm_status.toLowerCase()): true;
    });
  }, [
    alarms,
    alarmStatus,
  ]);

  const groupedAlarms = useMemo(() => { // groupedAlarms is a key-value pair, where the key is the device id
    // and value is the events/alarms that belong to the device.
    const groupedAlarms = {};
    for(const alarm of filteredAlarms) {
      if(groupedAlarms[alarm.device_id]) {
        if(alarm.is_current) {
          groupedAlarms[alarm.device_id].unshift(alarm);
        } else {
          groupedAlarms[alarm.device_id].push(alarm);
        }
      } else {
        groupedAlarms[alarm.device_id] = [alarm];
      }
    }
    return groupedAlarms;
  }, [filteredAlarms]);

  const nonExpandable = useMemo(() => { // The device which has only one event/alarm at the moment, no need to be able to expand.
    const nonExpandableRows = [];
    for(const alarms of Object.values(groupedAlarms)) {
      if(alarms.length === 1) nonExpandableRows.push(alarms[0].id);
    }
    return nonExpandableRows;
  }, [groupedAlarms]);

  const deviceLatestAlarms = useMemo(() => {  // The latest alarms for each device.
    const latestAlarms = [];
    for(const alarms of Object.values(groupedAlarms)) {
      const latestAlarm = alarms[0];
      if(moment(latestAlarm.alarm_timestamp).isSameOrAfter(cutoffDate)) {
        latestAlarms.push(latestAlarm);
      }
    }
    return latestAlarms;
  }, [groupedAlarms, cutoffDate]);

  const { preference: columnsPreference } = useLocalPreference({ key: tableName });

  const eventColumns = defaultColumns.map(col => (
    Array.isArray(col) ? columnWithFixedWidth(col[0], col[1]) : col
  ));
  const columnsWithWidth = useMemo(() => {
    return reorderColumns(columnsPreference, eventColumns);
  }, [defaultColumns, organisationUserColumnsWithWidth, columnsPreference]);

  const localisedColumnsWithWidth = useLocalisedColumns(columnsWithWidth);
  const localisedEventColumns = useLocalisedColumns(eventColumns);
  const renderHeader = useCallback(props => {
    return (
      <TableToolbar
        searchable
        title={t('Event_other')}
        loading={loading}
        lastFetch={lastFetch}
        error={error}
        tableProps={props}
        buttonGroups={[
          <AlarmDateFilter onDateChange={(value) => setDateRange(value)} disabled={loading || error} />,
          <Button
            as={DownloadTableCSVLink}
            columns={localisedColumnsWithWidth.map(col => Array.isArray(col) ? col[0] : col)}
            data={filteredAlarms}
            variant="outline-secondary"
            name="MOVUS-event-list"
            loading={loading}
            namespace="event-list"
          />,
          [
            <ButtonGroup key={3}>
              <ColumnsManager
                key={0}
                columns={localisedEventColumns}
                tableName={tableName}
                variant="outline-secondary"
              />
              {activeSubGroupId && <Button
                key={2}
                variant={includeSubGroup ? 'secondary' : 'outline-secondary'}
                onClick={() => setIncludeSubGroup(!includeSubGroup)}
              >
                {!includeSubGroup ? t('common.show') : t('common.hide') } {t('screens.alarms.subgroup-events')}
              </Button>}
            </ButtonGroup>
          ]
        ]}
      />
    );
  }, [loading, lastFetch, error, includeSubGroup, activeSubGroupId, filteredAlarms, columnsWithWidth]);

  return {
    renderHeader,
    filteredAlarms,
    groupedAlarms,
    nonExpandable,
    deviceLatestAlarms,
    columnsWithWidth,
  };
};

const MAX_SUB_ALARMS = 4;

function Events({
  activeSubGroupId,
  alarms=[],
  fetchAlarms,
  fetchDevices,
  loading,
  lastFetch,
  error,
  showTags,
  organisationUserColumns,
}) {
  const {query} = useLocation();
  const history = useHistory();

  const { renderHeader, groupedAlarms, nonExpandable, deviceLatestAlarms, columnsWithWidth } = useTableProps({
    alarmTypeFilter,
    activeSubGroupId,
    fetchAlarms,
    fetchDevices,
    loading,
    lastFetch,
    error,
    alarms,
    alarmStatus: query?.status,
    showTags,
    organisationUserColumns,
  });

  const placeholderColumn = {dataField: '', text: '', style: {width: '31px'}};
  const localisedColumns = useLocalisedColumns(columnsWithWidth);
  return (
    <Container fluid>
      <Table
        classes="table-fixed-width"
        pagination
        renderHeader={renderHeader}
        defaultSorted={defaultSorted}
        noDataIndication={NoDataIndication}
        loading={loading}
        columns={localisedColumns}
        data={deviceLatestAlarms}
        rowEvents = {{
          onClick: (e, alarm) => {
            if(window.getSelection().toString().length > 0) return;
            history.push(`/alarms/${alarm.id}`);
          }
        }}
        rowStyle={{
          cursor: 'pointer'
        }}
        striped={false}
        expandRow={{
          renderer: (row) => {
            const subAlarms = groupedAlarms[row.device_id].slice(1); // Not include the first alarm.
            const alarmsShown = subAlarms.slice(0, MAX_SUB_ALARMS);
            const hasMore = subAlarms.length > alarmsShown.length;
            const columns = [placeholderColumn, ...columnsWithWidth];
            const columnsWithWidthFooter = columns.map((col, index) => {
              if(!hasMore) return col;
              return {
                ...col,
                footer: () => index === 1 ? <Link to={`/equipment/${row.device_id}/activity`}>More events...</Link> : null,
                footerStyle: {
                  fontWeight: 'normal',
                },
              };
            });
            return (
              <Table
                classes="table-fixed-width sub-table-background"
                data={alarmsShown}
                columns={columnsWithWidthFooter}
                striped={false}
                hover={false}
                headerClasses="no-header"
                rowEvents = {{
                  onClick: (e, alarm) => {
                    if(window.getSelection().toString().length > 0) return;
                    history.push(`/alarms/${alarm.id}`);
                  }
                }}
                rowStyle={{
                  cursor: 'pointer'
                }}
              />
            );
          },
          showExpandColumn: true,
          expandByColumnOnly: true,
          nonExpandable,
          expandColumnRenderer: ({ expanded, rowKey }) => {
            if(nonExpandable.indexOf(rowKey) >= 0) return null; // Not show expand icon for non-expandable row.
            return (
              <ExpansionIndicator expanded={expanded} />
            );
          },
          className: "",
          // expanded: expandedRows,
          // onExpand: handleExpandRow,
        }}
      />
    </Container>
  );
}

const mapStateToProps = state => {
  const alarms = getAlarms(state, 'event');
  const { loading, lastFetch, error } = getAlarmListState(state);
  return {
    alarms,
    loading,
    lastFetch,
    error,
    activeSubGroupId: getActiveSubGroupId(state),
    showTags: getAlarmsShowTags(state),
  };
};

const mapDispatchToProps = { fetchAlarms, fetchDevices };

export default connect(mapStateToProps, mapDispatchToProps)(Events);
