import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IoIosInformationCircleOutline } from 'react-icons/io';

import BasicModal from '../../../components/BasicModal';

export function AlarmCausesFurtherInfoModal() {
  const { t } = useTranslation();
  const possibleCauses = ["movement", "vibration-in-mounting"];
  return (
    <BasicModal
      header={t('components.equipment.possible-causes.header')}
      body={(
        <div>
          <p>
            {t('components.equipment.possible-causes.further-info.header')}:
          </p>
          {possibleCauses.map((possibleCause) => (
            <div key={possibleCause}>
              <h5>{t(`components.equipment.possible-causes.further-info.${possibleCause}.heading`)}</h5>
              <p>{t(`components.equipment.possible-causes.further-info.${possibleCause}.info`)}</p>
            </div>
          ))}
        </div>
      )}
      footer={({close}) => (
        <Button onClick={close} variant="secondary">{t('OK')}</Button>
      )}
    >
      <div className="d-block d-flex">
        <sup>
          <Button size="sm" variant="primary-outline" className="p-0">
            <OverlayTrigger
              overlay={(
                <Tooltip>
                  {t(`components.equipment.possible-causes.further-info.tooltip`)}
                </Tooltip>
              )}
            >
              <IoIosInformationCircleOutline size="1.1em" />
            </OverlayTrigger>
          </Button>
        </sup>
      </div>
    </BasicModal>
  );
}
