import createReducer from '../../lib/createReducer';
import moment from 'moment';

import * as USER_ACTION_TYPES from '../user/types/ActionTypes';
import * as ACTION_TYPES from './types/ActionTypes';
import { upsertListItem, upsertListItems } from '../../lib/reducerUtils';
import { mapDeviceFromApi } from '../equipment/utils';
import { createReportDataKey } from './utils';

export const DEFAULT_STATE = {
  loading: false, // the list of organisations is being loaded
  lastFetch: null, // the last time we did a cloud fetch
  error: null, // any error loading the list

  // organisations is a dictionary
  // keyed by the API id
  organisations: [],
  groups: [],

  // stores the request state of group member requests by id
  groupRequestsById: {},
};


export default createReducer(DEFAULT_STATE, {
  [USER_ACTION_TYPES.LOGOUT]: () => ({ ...DEFAULT_STATE }),
  [USER_ACTION_TYPES.TOKEN_EXPIRED]: () => ({ ...DEFAULT_STATE }),
  [ACTION_TYPES.SELECT_ACTIVE_GROUP](state, { payload }) {
    return {
      ...state,
      activeGroupId: payload,
    };
  },
  [ACTION_TYPES.REQUEST_BEGIN](state) {
    return {
      ...state,
      loading: true
    };
  },

  [ACTION_TYPES.REQUEST_FAIL](state, {response}) {
    return {
      ...state,
      loading: false,
      error: response
    };
  },

  [ACTION_TYPES.RECEIVE_ORGANISATION_LIST](state, { response }) {

    const newOrgs = (
      response &&
      response._embedded &&
      response._embedded.organisations
    ) || [];

    const newState = {
      ...state,
      // for each returned item, upsert the filtered current list item
      organisations: upsertListItems(state.organisations, newOrgs),
      error: null,
      loading: false,
      lastFetch: moment()
    };

    return newState;
  },

  [ACTION_TYPES.RECEIVE_ORGANISATION_GROUPS](state, { organisation, response }) {
    return response && response._embedded && response._embedded.groups ? {
      ...state,
      organisations: upsertListItem(state.organisations, {
        id: organisation.id,
        _embedded: {
          groups: response._embedded.groups,
        },
      }),
    } : state;
  },

  [ACTION_TYPES.REQUEST_GROUP_BEGIN](state, { group }) {
    return {
      ...state,
      groupRequestsById: {
        ...state.groupRequestsById,
        [group.id]: {
          ...state.groupRequestsById && state.groupRequestsById[group.id],
          loading: true,
        },
      },
    };
  },

  [ACTION_TYPES.REQUEST_GROUP_FAIL](state, { group, response }) {
    return {
      ...state,
      groupRequestsById: {
        ...state.groupRequestsById,
        [group.id]: {
          ...state.groupRequestsById && state.groupRequestsById[group.id],
          loading: false,
          error: response,
        },
      },
    };
  },

  [ACTION_TYPES.RECEIVE_GROUP](state, { group, response }) {
    return response ? {
      ...state,
      groups: upsertListItem(state.groups, {
        id: group.id,
        ...response,
      }),
      groupRequestsById: {
        ...state.groupRequestsById,
        [group.id]: {
          ...state.groupRequestsById && state.groupRequestsById[group.id],
          loading: false,
          lastFetch: Date.now(),
          error: null,
        },
      },
    } : state;
  },

  [ACTION_TYPES.RECEIVE_UPDATE_GROUP](state, {group, data, response}) {
    Object.keys(data).forEach(key => data[key] === undefined && delete data[key]);  // Remove undefined value from data object.
    return response ? {
      ...state,
      groups: upsertListItem(state.groups, {
        id: group.id,
        ...data,
      }),
      groupRequestsById: {
        ...state.groupRequestsById,
        [group.id]: {
          ...state.groupRequestsById && state.groupRequestsById[group.id],
          loading: false,
          lastFetch: Date.now(),
          error: null,
        },
      },
    } : state;
  },

  [ACTION_TYPES.RECEIVE_DELETE_GROUP](state, { group, response }) {
    return response && group && group.id ? {
      ...state,
      // remove the group
      groups: state.groups.filter(({ id }) => id !== group.id),
    } : state;
  },

  [ACTION_TYPES.REQUEST_GROUP_MEMBERS](state, { group }) {
    return {
      ...state,
      groupRequestsById: {
        ...state.groupRequestsById,
        [group.id]: {
          ...state.groupRequestsById && state.groupRequestsById[group.id],
          loading: true,
        },
      },
    };
  },
  [ACTION_TYPES.RECEIVE_GROUP_MEMBERS](state, { group, response }) {
    return {
      ...state,
      groupRequestsById: {
        ...state.groupRequestsById,
        [group.id]: {
          ...state.groupRequestsById && state.groupRequestsById[group.id],
          loading: false,
          lastFetch: Date.now(),
        },
      },
      ...response && {
        groups: upsertListItem(state.groups, {
          id: group.id,
          _embedded: {
            // ensure that an empty array is written if 0 items are specifically found
            devices: ((response._embedded && response._embedded.devices) || []).map(mapDeviceFromApi),
            users: ((response._embedded && response._embedded.users) || []),
            groups: (response._embedded && response._embedded.groups) || [],
          },
        }),
      },
    };
  },
  [ACTION_TYPES.GROUP_MEMBERS_FAILURE](state, { group, response }) {
    return {
      ...state,
      groupRequestsById: {
        ...state.groupRequestsById,
        [group.id]: {
          ...state.groupRequestsById && state.groupRequestsById[group.id],
          loading: false,
          error: response,
        },
      },
    };
  },

  [ACTION_TYPES.RECEIVE_GROUP_ACCESS](state, { group, response }) {
    return {
      ...state,
      ...response && {
        groups: upsertListItem(state.groups, {
          id: group.id,
          _embedded: {
            // ensure that an empty array is written if 0 items are specifically found
            access: {
              users: (response._embedded && response._embedded.users) || [],
              groups:  (response._embedded && response._embedded.groups) || [],
            },
          },
        }),
      },
    };
  },

  [ACTION_TYPES.RECEIVE_GROUP_MEMBERS_AVAILABLE](state, { group, response }) {
    return response ? {
      ...state,
      groups: upsertListItem(state.groups, {
        id: group.id,
        _embedded: {
          // ensure that an empty array is written if 0 items are specifically found
          devices_available: ((response._embedded && response._embedded.devices) || []).map(mapDeviceFromApi),
        },
      }),
    } : state;
  },

  [ACTION_TYPES.RECEIVE_GROUP_RUNTIME](state, { group={}, response={} }) {
    return {
      ...state,
      groups: upsertListItem(state.groups, {
        id: group.id,
        _embedded: {
          runtime: (response._embedded && response._embedded.runtime) || [],
          // add embeded metadata object
          runtimeMetadata: {
            timezone: response.timezone,
          },
        },
      }),
    };
  },

  [ACTION_TYPES.RECEIVE_ORGANISATION_RUNTIME](state, { organisation={}, response={} }) {
    return {
      ...state,
      organisations: upsertListItem(state.organisations, {
        id: organisation.id,
        _embedded: {
          runtime: (response._embedded && response._embedded.runtime) || [],
          // add embedded metadata object
          runtimeMetadata: {
            timezone: response.timezone,
          },
        },
      }),
    };
  },

  [ACTION_TYPES.RECEIVE_CREATE_ORGANISATION](state, { responseHeaders, organisation }) {
    const organisationId = parseInt(responseHeaders.location.split('/')[2]);  // Get new organisation id from headers.
    // ResponseHeaders.location has the form of: '/organisations/{id}'.

    return {
      ...state,
      organisations: [...state.organisations, {id: organisationId, ...organisation}]
    };
  },

  [ACTION_TYPES.RECEIVE_ORGANISATION_TAGS](state, { organisation, response }) {
    return {
      ...state,
      organisations: upsertListItem(state.organisations, {
        id: organisation.id,
        // add selected logo details into object relations
        _embedded: {
          tags: response || {},
        },
      }),
    };
  },

  [ACTION_TYPES.RECEIVE_ORGANISATION_TOKENS](state, { organisation, response }) {
    return {
      ...state,
      organisations: upsertListItem(state.organisations, {
        id: organisation.id,
        // add selected token detail into object relations
        _embedded: {
          tokens: (response && response._embedded && response._embedded.tokens) || [],
        },
      }),
    };
  },

  [ACTION_TYPES.RECEIVE_ORGANISATION_TOKENS_DELETE](state, { organisation, token }) {
    const orgs = state.organisations.filter((org) => org.id === organisation.id);
    const org = orgs[0];
    const embedded = (org && org._embedded && org._embedded.tokens) || [];
    return {
      ...state,
      organisations: upsertListItem(state.organisations, {
        id: organisation.id,
        // update removed token detail into object relations
        _embedded: {
          tokens: embedded.filter((toCheck) => toCheck.token !== token),
        },
      }),
    };
  },

  [ACTION_TYPES.RECEIVE_STREAMING_CONFIGURATIONS](state, { organisation, response }) {
    return {
      ...state,
      organisations: upsertListItem(state.organisations, {
        id: organisation.id,
        // add selected token detail into object relations
        _embedded: {
          streams: (response && response._embedded && response._embedded.streams) || [],
        },
      }),
    };
  },

  [ACTION_TYPES.RECEIVE_STREAMING_CONFIGURATIONS_DELETE](state, { organisation, streamId }) {
    const orgs = state.organisations.filter((org) => org.id === organisation.id);
    const org = orgs[0];
    const embedded = (org && org._embedded && org._embedded.streams) || [];
    return {
      ...state,
      organisations: upsertListItem(state.organisations, {
        id: organisation.id,
        // update removed stream detail into object relations
        _embedded: {
          streams: embedded.filter((toCheck) => toCheck.id !== streamId),
        },
      }),
    };
  },

  [ACTION_TYPES.REQUEST_ORGANISATION_BEGIN](state, { organisation }) {
    return {
      ...state,
      organisations: upsertListItem(state.organisations, {
        ...organisation,
        // add flag to read fetching status
        _state_: {
          loading: true,
        },
      }),
    };
  },
  [ACTION_TYPES.REQUEST_ORGANISATION_FAIL](state, { organisation, response }) {
    return {
      ...state,
      organisations: upsertListItem(state.organisations, {
        ...organisation,
        // add flag to read fetching status change
        _state_: {
          loading: false,
          error: response,
        },
      }),
    };
  },

  [ACTION_TYPES.REQUEST_ORGANISATION_INFO](state, { organisation }) {
    return {
      ...state,
      organisations: upsertListItem(state.organisations, {
        ...organisation,
        // add flag to read fetching status
        _state_: {
          loading: true,
        },
      }),
    };
  },
  [ACTION_TYPES.ORGANISATION_INFO_FAILURE](state, { organisation, response='' }) {
    return {
      ...state,
      organisations: upsertListItem(state.organisations, {
        ...organisation,
        // add flag to read fetching status change
        _state_: {
          loading: false,
          error: `${response}`,
        },
      }),
    };
  },
  [ACTION_TYPES.RECEIVE_ORGANISATION_INFO](state, { response }) {
    return {
      ...state,
      organisations: upsertListItem(state.organisations, {
        ...response,
        // add selected logo details into object relations
        _embedded: {
          logo: {
            url: response.logo_url,
          },
          downtime_saved: {
            details: {
              // This matches the response from /organisations/{id}/downtime. Extra stuff not being
              // consumed now, but adding for consistency so it isn't wiped by the reducer.
              organisation_id: response.id,
              hours: response.downtime_saved_hours,
              _links: {
                self: `/organisations/${response.id}/downtime`
              }
            },
          },
        },
        // add flag so that it is possible to tell that the information
        // in this object contains at least one direct call to /organisations/[id]
        // rather than just information available on /organisations
        _state_: {
          loading: false,
          error: null,
          lastFetch: Date.now(),
        },
      }),
    };
  },
  [ACTION_TYPES.RECEIVE_UPDATE_ORGANISATION](state, { organisation, details }) {
    Object.keys(details).forEach(key => details[key] === undefined && delete details[key]);  // Remove undefined value from data object.
    return {
      ...state,
      organisations: upsertListItem(state.organisations, {
        ...organisation,
        ...details,
        _state_: {
          loading: false,
          error: null,
          lastFetch: Date.now(),
        },
      }),
    };
  },

  // note: this reducer requires organisation property "id" only
  [ACTION_TYPES.RECEIVE_ARCHIVE_ORGANISATION](state, { organisation }) {
    return {
      ...state,
      organisations: state.organisations.filter(({id}) => id !== organisation.id)
    };
  },

  // note: this reducer requires organisation property "id" only
  [ACTION_TYPES.RECEIVE_ORGANISATION_LOGO](state, { organisation, response }) {
    const {
      url,
      status,
    } = response;
    return {
      ...state,
      organisations: upsertListItem(state.organisations, {
        ...organisation,
        // add logo_url on the organisation
        logo_url: response.url,
        // add selected logo details into object relations
        _embedded: {
          logo: {
            url,
            status,
          },
        },
      }),
    };
  },

  [ACTION_TYPES.REQUEST_GROUP_IMPACT_CONFIG](state, { group = {} }) {
    return {
      ...state,
      groups: upsertListItem(state.groups, {
        id: group.id,
        _embedded: {
          impact_config: {
            _state_: {
              loading: true,
            },
          },
        },
      }),
    };
  },
  [ACTION_TYPES.GROUP_IMPACT_CONFIG_FAILURE](state, { group= {}, response = '' }) {
    return {
      ...state,
      groups: upsertListItem(state.groups, {
        id: group.id,
        _embedded: {
          impact_config: {
            _state_: {
              loading: false,
              error: response,
            },
          },
        },
      }),
    };
  },
  [ACTION_TYPES.RECEIVE_GROUP_IMPACT_CONFIG](state, { group = {}, response = {} }) {
    return {
      ...state,
      groups: upsertListItem(state.groups, {
        id: group.id,
        _embedded: {
          impact_config: {
            items: response._embedded?.settings || [],
            _state_: {
              loading: false,
              error: null,
              lastFetch: Date.now(),
            },
          },
        },
      }),
    };
  },

  [ACTION_TYPES.RECEIVE_ADD_GROUP_IMPACT_CONFIG](state, { group = {} }) {
    return {
      ...state,
      groups: upsertListItem(state.groups, {
        id: group.id,
        _embedded: {
          impact_config: {
            _state_: {
              loading: false,
              error: null,
              lastFetch: Date.now(),
            },
          },
        },
      }),
    };
  },

  [ACTION_TYPES.RECEIVE_DELETE_GROUP_IMPACT_CONFIG](state, { group = {}, config = {}}) {
    const newConfigData =
      state.groups.find(g => g.id === group.id) // Find the group by id
        ?._embedded?.impact_config?.items  // Find the current data in impact_config
        ?.filter(item => item.id !== config.id);  // Filter out the item to delete.
    return {
      ...state,
      groups: upsertListItem(state.groups, {
        id: group.id,
        _embedded: {
          impact_config: {
            items: newConfigData,
            _state_: {
              loading: false,
              error: null,
              lastFetch: Date.now(),
            },
          },
        },
      }),
    };
  },

  [ACTION_TYPES.RECEIVE_UPDATE_GROUP_IMPACT_CONFIG](state, { group = {}, config = {}, data = {}}) {
    const configData = state.groups.find(g => g.id === group.id)?._embedded?.impact_config?.items || [];
    const newConfigData = configData.map(item => (item.id === config.id ? {...item, ...data} : item));
    return {
      ...state,
      groups: upsertListItem(state.groups, {
        id: group.id,
        _embedded: {
          impact_config: {
            items: newConfigData,
            _state_: {
              loading: false,
              error: null,
              lastFetch: Date.now(),
            },
          },
        },
      }),
    };
  },

  [ACTION_TYPES.REQUEST_GROUP_IMPACT_SUMMARY](state, { group = {} }) {
    return {
      ...state,
      groups: upsertListItem(state.groups, {
        id: group.id,
        _embedded: {
          impact_summary: {
            _state_: {
              loading: true,
            },
          },
        },
      }),
    };
  },
  [ACTION_TYPES.GROUP_IMPACT_SUMMARY_FAILURE](state, { group = {}, response = '' }) {
    return {
      ...state,
      groups: upsertListItem(state.groups, {
        id: group.id,
        _embedded: {
          impact_summary: {
            _state_: {
              loading: false,
              error: response,
            },
          },
        },
      }),
    };
  },
  [ACTION_TYPES.RECEIVE_GROUP_IMPACT_SUMMARY](state, { group = {}, response = {} }) {
    return {
      ...state,
      groups: upsertListItem(state.groups, {
        id: group.id,
        _embedded: {
          impact_summary: {
            items: response._embedded?.events || [],
            _state_: {
              loading: false,
              error: null,
              lastFetch: Date.now(),
            },
          },
        },
      }),
    };
  },
  [ACTION_TYPES.RECEIVE_GROUP_LOCALE](state, { group = {}, response = {}}) {
    return {
      ...state,
      groups: upsertListItem(state.groups, {
        id: group.id,
        _embedded: {
          locale: response,
        }
      })
    };
  },
  [ACTION_TYPES.RECEIVE_ORGANISATION_LOCALE](state, { organisation = {}, response = {}}) {
    return {
      ...state,
      organisations: upsertListItem(state.organisations, {
        id: organisation.id,
        _embedded: {
          locale: response,
        }
      })
    };
  },
  [ACTION_TYPES.RECEIVE_GROUP_TAGS](state, { group, response }) {
    return {
      ...state,
      groups: upsertListItem(state.groups, {
        id: group.id,
        _embedded: {
          tags: response || {},
        },
      }),
    };
  },
  [ACTION_TYPES.REQUEST_GROUP_REPORT_DATA](state, { group = {}, params = {}}) {
    const reportDataKey = createReportDataKey(params);
    return {
      ...state,
      groups: upsertListItem(state.groups, {
        id: group.id,
        _embedded: {
          [reportDataKey]: {
            _state_: {
              loading: true,
            },
          },
        },
      }),
    };
  },
  [ACTION_TYPES.GROUP_REPORT_DATA_FAILURE](state, {group = {}, response = '', params = {}}) {
    const reportDataKey = createReportDataKey(params);
    return {
      ...state,
      groups: upsertListItem(state.groups, {
        id: group.id,
        _embedded: {
          [reportDataKey]: {
            _state_: {
              loading: false,
              error: response,
            },
          },
        },
      }),
    };
  },
  [ACTION_TYPES.RECEIVE_GROUP_REPORT_DATA](state, {group = {}, response = {}, params = {}}) {
    const reportDataKey = createReportDataKey(params);
    return {
      ...state,
      groups: upsertListItem(state.groups, {
        id: group.id,
        _embedded: {
          [reportDataKey]: {
            _state_: {
              loading: false,
              error: null,
              lastFetch: Date.now(),
            },
            items: response.rows || [],
          },
        },
      }),
    };
  },
  [ACTION_TYPES.RECEIVE_WEBHOOKS](state, {organisation, response}) {
    return {
      ...state,
      organisations: upsertListItem(state.organisations, {
        id: organisation.id,
        _embedded: {
          webhooks: response?._embedded || {},
        },
      }),
    };
  },
  [ACTION_TYPES.RECEIVE_UPDATE_WEBHOOKS](state, {organisation = {}, data = {}}) {
    const currentOrganisation = state.organisations?.find(o => o.id === organisation.id);
    const currentWebhooks = currentOrganisation._embedded?.webhooks || {};
    const currentEndpoints = currentWebhooks.endpoints || [];
    const existingEndpoint = currentEndpoints.find(ep => ep.endpoint === data.endpoint);
    const newEndpoints = existingEndpoint ? (
      data.webhook?.length ? currentEndpoints.map(ep => { // Update the endpoint.
        return ep.endpoint === data.endpoint ? {...ep, ...data} : ep;
      }) : currentEndpoints.filter(ep => ep.endpoint !== data.endpoint)) :  // Delete the endpoint if webhook is empty.
      [...currentEndpoints, data]; // Add the endpoint.

    return {
      ...state,
      organisations: upsertListItem(state.organisations, {
        id: organisation.id,
        _embedded: {
          webhooks: {...currentWebhooks, endpoints: newEndpoints},
        },
      }),
    };
  },
  [ACTION_TYPES.REQUEST_PLANT_VISIBILITY_HISTORY](state, { group = {} }) {
    return {
      ...state,
      groups: upsertListItem(state.groups, {
        id: group.id,
        _embedded: {
          plantVisibilityHistory: {
            _state_: {
              loading: true,
            },
          },
        },
      }),
    };
  },
  [ACTION_TYPES.PLANT_VISIBILITY_HISTORY_FAILURE](state, { group = {}, response = '' }) {
    return {
      ...state,
      groups: upsertListItem(state.groups, {
        id: group.id,
        _embedded: {
          plantVisibilityHistory: {
            _state_: {
              loading: false,
              error: response,
            },
          },
        },
      }),
    };
  },
  [ACTION_TYPES.RECEIVE_PLANT_VISIBILITY_HISTORY](state, { group = {}, response = {} }) {
    return {
      ...state,
      groups: upsertListItem(state.groups, {
        id: group.id,
        _embedded: {
          plantVisibilityHistory: {
            _state_: {
              loading: false,
              error: null,
              lastFetch: Date.now(),
            },
            items: response._embedded?.devices || [],
          },
        },
      }),
    };
  },
  [ACTION_TYPES.REQUEST_PLANT_VISIBILITY_SCHEDULE](state, { group = {} }) {
    return {
      ...state,
      groups: upsertListItem(state.groups, {
        id: group.id,
        _embedded: {
          plantVisibilitySchedule: {
            _state_: {
              loading: true,
            },
          },
        },
      }),
    };
  },
  [ACTION_TYPES.PLANT_VISIBILITY_SCHEDULE_FAILURE](state, { group = {}, response = '' }) {
    return {
      ...state,
      groups: upsertListItem(state.groups, {
        id: group.id,
        _embedded: {
          plantVisibilitySchedule: {
            _state_: {
              loading: false,
              error: response,
            },
          },
        },
      }),
    };
  },
  [ACTION_TYPES.RECEIVE_PLANT_VISIBILITY_SCHEDULE](state, { group ={}, response = {} }) {
    return {
      ...state,
      groups: upsertListItem(state.groups, {
        id: group.id,
        _embedded: {
          plantVisibilitySchedule: {
            _state_: {
              loading: false,
              error: null,
              lastFetch: Date.now(),
            },
            items: response._embedded?.devices || [],
          },
        },
      }),
    };
  },
  [ACTION_TYPES.RECEIVE_UPDATE_PLANT_VISIBILITY_SCHEDULE](state, { group={}, device={}, schedule={}, data, response={} }) {
    const groupData = state.groups.filter(thisGroup => thisGroup.id === group.id);
    let newGroupData = {};
    if (groupData && groupData.length > 0) {
      const thisGroup = groupData[0];
      const configData = thisGroup?._embedded?.plantVisibilitySchedule?.items || [];
      newGroupData = configData.map((item) => {
        const newSchedules = item.schedule.map((thisSchedule) => {
          const { start, end, comments } = data;
          const newSchedule = {
            // Can't find a more concise way to do this. Stack Overflow has bananas long options. Wait until
            // handling timezones properly to add complexity.
            id: thisSchedule.id,
            start: `${start}T00:00:00`,
            end: `${end}T00:00:00`,
            colour: schedule.colour,
            comments,
          };
          return thisSchedule.id === schedule.id ? newSchedule : thisSchedule;
        });
        const newItem = {
          ...item,
          schedule: newSchedules,
        };
        return item.id === device.id ? newItem : item;
      });
    }
    return {
      ...state,
      groups: upsertListItem(state.groups, {
        id: group.id,
        _embedded: {
          plantVisibilitySchedule: {
            _state_: {
              loading: false,
              error: null,
              lastFetch: Date.now(),
            },
            items: newGroupData || [],
          },
        },
      }),
    };
  },
  [ACTION_TYPES.REQUEST_ORG_ALARM_DOWNTIME_SAVED](state, { organisation }) {
    return {
      ...state,
      organisations: upsertListItem(state.organisations, {
        id: organisation.id,
        _embedded: {
          downtime_saved: {
            _state_: {
              loading: true,
            }
          }
        }
      })
    };
  },
  [ACTION_TYPES.RECEIVE_ORG_ALARM_DOWNTIME_SAVED](state, { organisation={}, response={} }) {
    return {
      ...state,
      organisations: upsertListItem(state.organisations, {
        id: organisation.id,
        _embedded: {
          downtime_saved: {
            details: response,
            _state_: {
              loading: false,
              lastFetch: Date.now(),
              error: null,
            }
          }
        }
      })
    };
  },
  [ACTION_TYPES.ORG_ALARM_DOWNTIME_SAVED_FAILURE](state, { organisation, response }) {
    return {
      ...state,
      organisations: upsertListItem(state.organisations, {
        id: organisation.id,
        _embedded: {
          downtime_saved: {
            _state_: {
              loading: false,
              error: response,
            }
          }
        }
      })
    };
  },
});
