
import React from 'react';
import { Link } from 'react-router-dom';
import { Badge, ButtonGroup } from 'react-bootstrap';

import {
  headerFormatter,
  relativeDateFormatter,
  completeDateFormatter,
  relativeDateFilter,
  completeDateFilter,
} from '../../components/table/formatters';

import {
  stringSortFunc,
  timeSortFunc,
} from '../../components/table/utils';
import { downtimeSavedIsHours } from '../../lib/utils';

import { AlarmTypeBadge } from '../equipment/components/DeviceCard';
import UserPicture from '../user/components/UserPicture';
import EditCommentButton from '../equipment/components/EditCommentButton';
import DeleteCommentButton from '../equipment/components/DeleteCommentButton';
import VolumeImage from './VolumeImage';
import ToneImage from './ToneImage';
import TemperatureImage from './TemperatureImage';
import Bar from '../../components/Bar';
import { AcknowledgeActionButton } from '../equipment/components/DeviceAlarmStatus';
import { column as rms } from '../equipment/columns/rms';
import { column as temperature } from '../equipment/columns/temperature';
import ColourBadge from '../../components/ColourBadge';
import AlarmCountPopup from './components/AlarmCountPopup';

export const alarmTimestamp = {
  dataField: 'alarm_timestamp',
  text: 'Date/Time',
  headerFormatter,
  formatter: (value, { id }) => (
    <>
      {relativeDateFormatter(value) || "N/A"}
    </>
  ),
  filterValue: relativeDateFilter,
  sort: true,
  sortFunc: timeSortFunc,
  __csvTextFormatter: (value) => relativeDateFilter(value),
};

export const eventTimeStamp = {
  ...alarmTimestamp,
  formatter: (value) => completeDateFormatter(value) || "N/A",
  __csvTextFormatter: (value) => completeDateFilter(value),
};

export const alarmDescription = {
  dataField: 'alarm_description',
  text: 'Alarm Name',
  headerFormatter,
  formatter: (value, { id }) => (
    <Link to={`/alarms/${id}`}>
      {value}
    </Link>
  ),
  sort: true,
  sortFunc: stringSortFunc,
};

export const userAlarmName = {
  ...alarmDescription,
  text: 'Summary',
  formatter: cell => cell.length > 80 ? cell.substring(0, 80) + '...' : cell,
};

export const triggerDescription = {
  dataField: 'trigger_description',
  text: 'Threshold',
  headerFormatter,
  sort: true,
  sortFunc: stringSortFunc,
};

export const userAlarmDescription = {
  ...triggerDescription,
  text: 'Description',
  formatter: cell => cell.length > 120 ? cell.substring(0, 120) + '...' : cell,
};

const isClosedAsInvalid = (closed_as) => {
  return closed_as?.toString().toLowerCase().includes('invalid');
};

const AlarmStatusBadge = ({status, closed_as}) => {
  switch(status) {
    case 'new': return <Badge variant="danger">New</Badge>;
    case 'acknowledged': return <Badge variant="warning">Acknowledged</Badge>;
    case 'valid': return <Badge variant="success">Valid</Badge>;
    case 'invalid': return <Badge variant="secondary">Invalid</Badge>;
    case 'scheduled': return <Badge variant="info">Scheduled</Badge>;
    case 'in_progress': return <Badge variant="info">In Progress</Badge>;
    case 'resolved': return <Badge variant="info">Resolved</Badge>;
    case 'closed':
      return <Badge
        variant={isClosedAsInvalid(closed_as) ? "light" : "dark"}
        style={{ background: isClosedAsInvalid(closed_as) && "#ddd", maxWidth: '108px', overflow: 'hidden', textOverflow: 'ellipsis' }}
        // If closed_as contains text 'invalid', show a different style.
      >
        {closed_as || 'Closed'}
      </Badge>;
    default: return <Badge variant="light">{status}</Badge>; // Return the data with default style.
  }
};

export const statusBadge = {
  dataField: 'alarm_status',
  text: 'Status',
  headerFormatter,
  formatter: (value, { closed_as }) => {
    return <AlarmStatusBadge status={value} closed_as ={closed_as} />;
  },
  style: {
    width: '140px'
  }
};

export const alarmTypeBadge = {
  dataField: 'alarm_type',
  text: 'Alarm type',
  headerFormatter,
  formatter: (value) => <AlarmTypeBadge alarmType={value} />,
  sort: true,
  sortFunc: stringSortFunc
};

// export const eventTypeBadge = {
//   dataField: 'alarm_type_extra',
//   text: 'Event Type',
//   formatter: (value, { alarm_type_colour }) => (<ColourBadge colour={alarm_type_colour}>{value}</ColourBadge>)
// };

export const createdBy = {
  dataField: 'created_by_name',
  text: 'Reported By',
  headerFormatter,
  sort: true,
  sortFunc: stringSortFunc,
};

export const eventDateTime = {
  dataField: 'event_time',
  text: 'Date/Time',
  headerFormatter,
  formatter: (value, event) => (
    <>{completeDateFormatter(value) || "N/A"}</>
  ),
  sort: true,
  sortFunc: timeSortFunc,
  style: {
    width: '180px',
    fontSize: '16px',
  }
};

export const user = {
  dataField: 'user_name',
  text: 'User',
  headerFormatter,
  formatter: (value, {user_id}) => {
    if(user_id) {
      return (
        <>
          <Link to={`/users/${user_id}`}><UserPicture userId={user_id} /></Link>
          {' '}
          <Link to={`/users/${user_id}`}>{value || "N/A"}</Link>
        </>
      );
    }
    return <span><UserPicture userId={user_id} /> System</span>;
  },
  style: {
    width: '200px'
  }
};

export const categoryColorBadge = {
  dataField: 'category',
  text: 'Category',
  formatter: (value, {category_colour}) => {
    if(Array.isArray(value)) {
      return <ColourBadge colour={category_colour}>
        {value.map((v, i) => {
          return <span key={i}>{v}<br /></span>;
        })}
      </ColourBadge>;
    }
    return <ColourBadge colour={category_colour}>{value}</ColourBadge>;
  }
};

export const comment = {
  dataField: 'comment',
  text: 'Comment/Description',
  headerFormatter,
  formatter: (value, {description=''}) => (value || '') + description,  // It's assumed that this column either shows comment or description.
  style: {
    minWidth: '200px',
    maxWidth: '280px'
  }
};

export const eventTrigger = {
  dataField: '_trigger',
  text: 'Original Event Trigger',
  headerFormatter,
  sort: false,
  formatter: (value, row) => {
    if(row.alarm_source === 'user') {
      return (
        <div style={{minWidth: '260px'}}>{row.alarm_description}</div>
      );
    }
    if(row.alarm_source === 'threshold') {
      return <div style={{minWidth: '260px'}}>{row.trigger_description}</div>;
    }
    return (
      <div style={{minWidth: '260px', maxWidth: '300px'}} className="d-flex align-items-center">
        <div className="flex-grow-1 mx-1">
          {/* Condition */}
          <Bar conditionValue={row.condition_overall} />
        </div>
        <div className="d-flex flex-column align-items-center mx-1">
          {/* AI Volume */}
          <VolumeImage rms_value={row.rms} isoClass={row.equipment_iso_class} label="AI Volume" />
        </div>
        <div className="d-flex flex-column align-items-center mx-1">
          {/* AI Tone */}
          <ToneImage vibration_condition={row.condition_vibration} />
        </div>
        <div className="d-flex flex-column align-items-center mx-1">
          {/* AI Temp */}
          <TemperatureImage temperature_condition={row.condition_temperature} />
        </div>
      </div>
    );
  },
  __csvTextFormatter: (value, row) => {
    if(row.alarm_source === 'user') return row.alarm_description;
    if(row.alarm_source === 'threshold') return row.trigger_description;
    return row.condition_overall;
  },
};

export const alarmCount = {
  dataField: 'alarm_count',
  text: 'Alarms',
  headerFormatter,
  sort: true,
  formatter: (value, row) => (<AlarmCountPopup count={value} alarm={row} />)
};

export const latestAlarm = {
  dataField: 'latest_alarm_timestamp',
  text: 'Most recent alarm',
  formatter: (value, { latest_alarm_id }) => (
    <Link to={`/alarms/${latest_alarm_id}`} onClick={(e) => e.stopPropagation()}>
      {completeDateFormatter(value) || "N/A"}
    </Link>
  ),
  sort: true,
  sortFunc: timeSortFunc,
};

export const latestRms = {
  ...rms,
  dataField: 'latest_rms',
};

export const latestTemperature = {
  ...temperature,
  dataField: 'latest_tmp',
};

export const eventActions = {
  dataField: '_actions',
  text: 'Actions',
  formatter: (value, event) => (
    <ButtonGroup>
      {event.can_edit && <EditCommentButton event={event} className="btn btn-outline-secondary" />}
      {event.can_delete && <DeleteCommentButton event={event} className="btn btn-outline-secondary" />}
      {event.event_alarm_id &&
        <AcknowledgeActionButton
          alarm={{...event, id: event.event_alarm_id, eventId: event.alarm_id}}
          variant="outline-secondary" >
            New Event
        </AcknowledgeActionButton>}
    </ButtonGroup>
  ),
  style: {
    width: '125px',
  }
};

export const current = {
  dataField: 'is_current',
  text: 'Current',
  formatter: (value, {is_current, was_current}) => (
    <>
      {is_current === true && <span className="badge border border-danger bg-danger text-white mx-1">EVT</span>}
      {was_current === true && <span className="badge border border-danger text-danger mx-1">EVT</span>}
    </>
  ),
};

export const alarmStatusExtra = {
  dataField: 'alarm_status_extra',
  text: 'Status',
  formatter: (value, {alarm_status_colour}) => {
    if(Array.isArray(value)) {
      return <ColourBadge colour={alarm_status_colour}>
        {value.map((v, i) => {
          return <span key={i}>{v}<br /></span>;
        })}
      </ColourBadge>;
    }
    return <ColourBadge colour={alarm_status_colour}>{value}</ColourBadge>;
  },
  __csvTextFormatter: (value) => Array.isArray(value) ? value.join(' ') : value,
};

export const alarmTypeExtra = {
  dataField: 'alarm_type_extra',
  text: 'Alarm Type',
  formatter: (value, {alarm_type_colour}) => {
    if(Array.isArray(value)) {
      return <ColourBadge colour={alarm_type_colour}>
        {value.map((v, i) => {
          return <span key={i}>{v}<br /></span>;
        })}
      </ColourBadge>;
    }
    return <ColourBadge colour={alarm_type_colour}>{value}</ColourBadge>;
  },
  __csvTextFormatter: (value) => Array.isArray(value) ? value.join(' ') : value,
};

export const eventTypeExtra = {
  ...alarmTypeExtra,
  text: 'Event Type',
};

export const transitionedToBadge = {
  ...alarmTypeExtra,
  text: 'Transitioned to'
};

export const alarmCriticality = {
  text: 'Criticality',
  __showTag: 'criticality',
  dataField: 'tags.criticality',
};

export const createNotification = {
  // @TODO: Complete the function of this column object.
  text: 'Create Notification',
  dataField: 'createNotification',
};

export const downtimeSavedHours = {
  dataField: 'downtime_saved',
  text: 'Downtime Saved (Hours)', // This is a column title, so we won't do i18n singular vs plural
  sort: true,
  formatter: cell => downtimeSavedIsHours({ hours: cell }) ? parseInt(cell) : '-',
};
