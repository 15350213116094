// Expected behaviour of Series, Y-axis label and Legend in different modes
/*
*
* Series + Y-axis   Temperature   RMS       Trendline   RCO
* Default/Initial   √             √~        √           x
* Add RMS alarm     √             √~        √           x
* Add TMP alarm     √             √~        √           x
* Edit RCO          x             √^        √           √
* Edit learning     √             √~        √           x
* Edit axis         √             √~        √           x
*
* Trendline has no Y-axis label, only Series and Legend.
* ~All screens except RCO use RMS filtered/selected data i.e. rms tag for v2.1 or rms2 tag for v3.x.
* ^Edit RCO uses RMS unfiltered i.e. rms tag AKA mms.
*
* Legend Selected   Temperature   RMS       Trendline   RCO
* Default/Initial   √             √         x`          N/A
* Add RMS alarm     x             √         x`          N/A
* Add TMP alarm     √             x         x`          N/A
* Edit RCO          N/A           √         x`          √
* Edit learning     √             √         x`          N/A
* Edit axis         √             √         x`          N/A
*
* `Selecting Trendline, de-selects Temperature.
*/

const baseDisplay = {
  displayTemperatureData: true,
  displayRmsData: false,
  displayRmsSelectedData: true,
  displayRunningCutoffData: false,
};
const baseSelect = {
  selectRmsTrendline: false,
  selectRco: false,
};
const commonSelect = {
  selectTemperature: true,
  selectRms: true,
};
const initialDisplayState = {
  current: 'initialDisplayState',
  ...baseDisplay,
  ...baseSelect,
  ...commonSelect,
};
const rmsDisplayState = {
  current: 'rmsDisplayState',
  ...baseDisplay,
  ...baseSelect,
  selectTemperature: false,
  selectRms: true,
};
const tmpDisplayState = {
  current: 'tmpDisplayState',
  ...baseDisplay,
  ...baseSelect,
  selectTemperature: true,
  selectRms: false,
};
const rcoDisplayState = {
  current: 'rcoDisplayState',
  displayTemperatureData: false,
  displayRmsData: true,
  displayRmsSelectedData: false,
  displayRunningCutoffData: true,
  selectTemperature: false,
  selectRms: true,
  selectRmsTrendline: false,
  selectRco: true,
};
const learningDisplayState = {
  current: 'learningDisplayState',
  ...baseDisplay,
  ...baseSelect,
  ...commonSelect,
};
const axisDisplayState = {
  current: 'axisDisplayState',
  ...baseDisplay,
  ...baseSelect,
  ...commonSelect,
};
export const initialDisplayModeStates = {
  initialDisplayState: initialDisplayState,
  rmsDisplayState: rmsDisplayState,
  tmpDisplayState: tmpDisplayState,
  rcoDisplayState: rcoDisplayState,
  learningDisplayState: learningDisplayState,
  axisDisplayState: axisDisplayState,
};

export const DEFAULT_MODE_SETTINGS = 'init';
export function displayReducer(state, action) {
  const options = action.options;
  switch (action.type) {
    case DEFAULT_MODE_SETTINGS:
      const current = options.current;
      return {
        ...initialDisplayModeStates[current],
      };
    case 'selectRmsTrendline': {
      const current = state.current;
      const trendlineOn = options.selectRmsTrendline;
      const selectTemperature = current === 'rcoDisplayState' ? false : !trendlineOn;
      return {
        ...state,
        ...action.options,
        selectTemperature: selectTemperature,
      };
    }
    case 'selectOthers': {
      return {
        ...state,
        ...action.options,
      };
    }
    default:
      throw new Error(`Dispatch type not supported: ${action.type}`);
  }
}
