// Errors that BugSnag doesn't need to get. A major version upgrade of Axios changed what raised
// and BugSnag got super chatty about errors we don't care about logging.
export const NOT_LOGGED_IN = 'not-logged-in';
export const HTTP_FORBIDDEN = 403;
export const HTTP_GATEWAY_TIMEOUT = 504;
// https://stackoverflow.com/questions/3825581/does-an-http-status-code-of-0-have-any-meaning/26451773#26451773
// https://stackoverflow.com/a/26451773/8590017 -> Best answer from above
export const NETWORK_ERROR = 0;

// https://movusoz.atlassian.net/browse/DASH-1165
export const IGNORE_EVENTS_SUMMARY = 'eventssummary';

export const POLLING = 'polling';

export const API_TIMEOUT_MS = 60000;
// https://movusoz.atlassian.net/browse/DASH-1181
// Text is generated by Axios, with the timeout created by us. Required by us only for ignoring it.
export const TIMEOUT_EXCEEDED = 'timeout of {API_TIMEOUT_MS}ms exceeded';

export const movus = 'MOVUS';

// Downtime Saved
export const downtimeSavedForm = {
  minimumHours: 0,
  maximumHours: 999,
  placeholder: '#',
};
