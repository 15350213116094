import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { Button, Form, Row, Col, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import EquipmentPageLayout from '../components/EquipmentPageLayout';
import Title from '../../../components/Title';
import useDeviceSettings from '../hooks/useDeviceSettings';

function EquipmentSettings() {
  const { t } = useTranslation();
  const { id } = useParams();
  const deviceId = Number(id);

  const { loading, lastFetch, error, settings = {}, updateSettings } = useDeviceSettings(deviceId, { fetch: true });

  const setDropdown = (values) => {
    // Populate dropdowns with stored value (if any) from API or set to first entry in option array.
    const newObj = {};
    Object.entries(values).forEach(([key, value]) => {
      if (key.includes('_dropdown')) {
        // In the API the related fields must have the same prepended before dropdown and value,
        // e.g. dg_asset_drive_type_dropdown and dg_asset_drive_type_value.
        const dropdownName = key.split('_dropdown')[0];
        const newKey = `${dropdownName}_value`;
        const setValue = values[newKey];
        if (setValue !== '') newObj[key] = setValue;
        else newObj[key] = value[0]; // Set default selected
      } else {
        newObj[key] = value;
      }
    });
    return newObj;
  };

  const parseDropdown = (data) => {
    // Parse selected from dropdown to set the actual value to be stored in the database. The
    // field e.g. dg_asset_drive_type_dropdown stores the options, whereas
    // e.g. dg_asset_drive_type_value stores the customer's option choice.
    const newObj = {};
    Object.entries(data).forEach(([key, value]) => {
      if (key.includes('_dropdown')) {
        const dropdownName = key.split('_dropdown')[0];
        const newKey = `${dropdownName}_value`;
        newObj[newKey] = value;
      } else {
        // Otherwise we'll overwrite the newly created one as the API has the _value as a hidden field
        if (!(key in newObj)) {
          newObj[key] = value;
        }
      }
    });
    return newObj;
  };

  const defaultValues = useMemo(() => {
    const values = {};
    for(const key of Object.keys(settings)) {
      values[key] = settings[key].value;
    }
    return setDropdown(values);
  }, [settings]);

  const { register, handleSubmit, reset } = useForm({
    mode: 'onSubmit',
    defaultValues
  });

  useEffect(() => {
    if(defaultValues && Object.keys(defaultValues).length > 0) {
      reset(defaultValues);
    }
  }, [defaultValues]);

  const onSubmit = (data) => {
    data = parseDropdown(data);
    updateSettings(data);
  };

  return (
    <EquipmentPageLayout>
      <div className="my-4 mx-3">
        <Title
          title={t('screens.equipment.equipment-settings.title')}
          loading={loading}
          lastFetch={lastFetch}
          error={error}
        />
        <Form onSubmit={handleSubmit(onSubmit)}>
          {
            Object.keys(settings).map(key => {
              const {label, description, value_type, value, new_section, disabled } = settings[key];
              if(value_type === 'heading1') {
                return (
                  <div key={key}>
                    <h4>{label}</h4>
                    {description && <p className="text-muted">{description}</p>}
                  </div>
                );
              }
              if(value_type === 'heading2') {
                return (
                  <div key={key}>
                    <h6>{label}</h6>
                    {description && <p className="text-muted">{description}</p>}
                  </div>
                );
              }
              if(value_type === 'paragraph') {
                return (
                  <div key={key}>
                    {description && <p className="text-muted">{description}</p>}
                  </div>
                );
              }
              return (
                <div key={key}>
                  {new_section && <div className="border-bottom border-secondary mb-3"></div>}
                  <Form.Group as={Row} controlId={key}>
                    <Form.Label column sm="3">
                      {label}
                    </Form.Label>
                    <Col sm="9">
                      {value_type === 'bool' ?
                        <Form.Check type="checkbox" {...register(key)} /> :
                        value_type === 'number' ?
                          <Form.Control
                            type="number"
                            step="any"
                            {...register(key, {valueAsNumber: true})}
                            style={{width: '25%', minWidth: '8em'}}
                            disabled={disabled}
                          /> : value_type === 'image' ?
                            <div className="position-relative">
                              {/* value.src must resolve to file in public directory e.g.
                               src="/fitmachine-orientation.png" is "public/fitmachine-orientation.png" */}
                              <Image src={value.src} alt={value.alt} fluid />
                            </div> : value_type === 'dropdown' ?
                              <Form.Control
                                as="select"
                                defaultValue={''}
                                {...register(key)}
                                style={{width: '50%', minWidth: '8em'}}
                              >
                                {value.map((item) => <option key={item} value={item}>{item}</option>)}
                              </Form.Control> : value_type === 'hidden' ?
                                null :
                                <Form.Control
                                  type="text"
                                  {...register(key)}
                                />
                      }
                      <Form.Text className="text-muted">{description}</Form.Text>
                    </Col>
                  </Form.Group>
                </div>
              );
            })
          }
          <Button
            variant="success"
            type="submit"
            size="lg"
            className="float-right"
            disabled={loading}
          >{t('common.save')}</Button>
        </Form>
      </div>
    </EquipmentPageLayout>
  );
}

export default EquipmentSettings;
