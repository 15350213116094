import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Form, Button, ButtonGroup, Alert } from 'react-bootstrap';
import { IoIosCube } from 'react-icons/io';
import { withTranslation, useTranslation } from 'react-i18next';

import { generateItemCountText, getFormValues, showUnsavedChangesToast } from '../../../lib/utils';

import { fetchUserDevices, submitUserDevices } from '../actions';

import Table from '../../../components/Table';
import {
  headerFormatter,
  nullFormatter,
} from '../../../components/table/formatters';

import Toolbar from '../../../components/TableToolbar';

import {
  siteName,
  subAreaName,
  equipmentName,
  archived,
} from '../../equipment/columns';

import { getUser, getUserDevices } from '../selectors';
import { formatTableColumn } from '../../../lib/formatTableColumn';

const defaultSorted = [{
  dataField: 'equipment_name',
  order: 'asc'
}];

function setInputValues(name, value) {
  const form = document.getElementById('edit-user-form');
  if (form) {
    const inputNameRegex = /^(\w+)\[(\d+)\]$/;
    [...form.elements].forEach(el => {
      if (!el.disabled && el.name.startsWith(name) && inputNameRegex.test(el.name)) {
        el.checked = value ? 'checked' : undefined;
      }
    });
  }
}

// todo: move this to helper as it is repeated multiple times in codebase
function SelectAllButton(props) {
  return (
    <Button size="sm" variant="outline-primary" {...props} />
  );
}

function SelectAllHeader(props, ...rest) {
  const { dataField } = props;
  const { t } = useTranslation();
  return (
    <Fragment>
      {headerFormatter(props, ...rest)}
      <ButtonGroup>
        <SelectAllButton onClick={() => {
          setInputValues(dataField, true);
          showUnsavedChangesToast();
        }}>
          {t('All')}
        </SelectAllButton>
        <SelectAllButton onClick={() => {
          setInputValues(dataField, false);
          showUnsavedChangesToast();
        }}>
          {t('None')}
        </SelectAllButton>
      </ButtonGroup>
    </Fragment>
  );
}
function selectAllHeaderFormatter(props, ...rest) {
  return <SelectAllHeader {...props} {...rest} />;
}

export function sortByActiveThenString(a, b, order, dataField, rowA, rowB) {
  const negative = order === 'asc' ? 1 : -1;
  return rowA.active !== rowB.active
    // prioritise sorting by activeness
    ? rowB.active - rowA.active
    // then sort by value
    : negative * a.localeCompare(b);
}

export function sortByActiveThenNumber(a=0, b=0, order, dataField, rowA, rowB) {
  const negative = order === 'asc' ? 1 : -1;
  return rowA.active !== rowB.active
    // prioritise sorting by activeness
    ? rowB.active - rowA.active
    // then sort by value
    : negative * (a - b);
}

const columns = [{
  ...siteName,
  sortFunc: sortByActiveThenString,
}, {
  ...subAreaName,
  sortFunc: sortByActiveThenString,
  dataField: 'site_subarea_name',
}, {
  ...equipmentName,
  sortFunc: sortByActiveThenString,
}, {
  ...archived,
  text: 'Status',
  sortFunc: sortByActiveThenNumber,
}, {
  dataField: 'display',
  text: 'Read access',
  headerFormatter: selectAllHeaderFormatter,
  formatter: (value, { device_id, active }) => (
    <Form.Check
      key={`display-${device_id}`}
      type="checkbox"
      name={`display[${device_id}]`}
      defaultChecked={!!value}
      onChange={e => {
        // checked is the value which it will become (and has already changed to)
        setInputValues(`display[${device_id}]`, !!e.target.checked);
        showUnsavedChangesToast();
      }}
      disabled={!active}
    />
  ),
  filterValue: nullFormatter,
  sort: false,
}];

const columnsWithArchivedStatus = columns;
const columnsWithoutArchivedStatus = columns.map(column => {
  // add a hidden flag to columns we want to filter, *do not* change the number of column passed to
  // react-bootstrap-table2, it will not re-render what it believes are "unaffected" rows correctly
  return { ...column, hidden: column.dataField === 'archived' };
});

class EditUserDevices extends Component {

  state = {
    annotatedDevices: undefined,
    submit: {},
    filter: '',
  };

  componentDidMount() {
    this.loadDevicesList();
  }

  componentDidUpdate(prevProps) {
    // update the device list if the user was updated
    if (prevProps.userToEditId !== this.props.userToEditId) {
      this.loadDevicesList();
    }
  }

  loadDevicesList = async ({ force=true } = {}) => {
    const {
      userIsNew,
      userIsSelf,
      userToEdit,
      userDevices = [],
      fetchUserDevices,
    } = this.props;

    if (userIsSelf || userIsNew || userToEdit.user_type !== 'User') {
      return null;
    }

    // fetch user's devices if needed
    if (force || !userDevices.length) {
      const submittedAt = Date.now();
      this.setState(({ submit: { submittedAt } }));
      try {
        await fetchUserDevices(userToEdit);
        this.setState(({ submit }) => {
          if (submit.submittedAt === submittedAt) {
            return { submit: { succeededAt: new Date() } };
          }
        });
      }
      catch (error) {
        this.setState(({ submit }) => {
          if (submit.submittedAt === submittedAt) {
            return { submit: { error: error.message || 'Error' } };
          }
        });
      }
    }
  };

  handleSubmit = async e => {
    e.preventDefault();
    const formValues = getFormValues(e);
    const {
      submitUserDevices,
      fetchUserDevices,
      userToEdit,
    } = this.props;

    // split out user device things from the main payload and format them
    // transform the checkbox input element name/value pairs into a devices array
    const deviceInputNameRegex = /^(display)\[(\d+)\]$/;
    const deviceListById = Object.entries(formValues).reduce((acc, [key, value]) => {
      // get the identifiers from the name format
      const [match, name, device_id] = key.match(deviceInputNameRegex) || [];
      if (match) {
        // get or add an get device props by device id
        acc[device_id] = acc[device_id] || { device_id: parseInt(device_id) };
        // add the value into the device props
        acc[device_id][name] = value;
        // remove this key from the main formValues payload
        delete formValues[key];
      }
      return acc;
    }, {});
    const devicesList = Object.values(deviceListById);

    const submittedAt = Date.now();
    this.setState(({ submit: { submittedAt } }));
    try {
      await submitUserDevices(userToEdit, devicesList);
      await fetchUserDevices(userToEdit);
      this.setState(({ submit }) => {
        if (submit.submittedAt === submittedAt) {
          return { submit: { succeededAt: new Date() } };
        }
      });
    }
    catch (error) {
      this.setState(({ submit }) => {
        if (submit.submittedAt === submittedAt) {
          return { submit: { error: error.message || 'Error' } };
        }
      });
    }
  };

  renderItemCount = () => {
    const { userDevices=[] } = this.props;
    const { filter, annotatedDevices=[] } = this.state;
    const count = userDevices.length;
    const filteredCount = annotatedDevices.filter(({ active }) => !!active).length;
    return generateItemCountText(filter, count, filteredCount);
  };

  renderSearchBar = () => {
    const { t } = this.props;
    return (
      // copy style from output of default rendered search bar
      <label htmlFor="search-bar-user-devices" className="search-label">
        <input
          id="search-bar-user-devices"
          type="text"
          aria-label="enter text you want to search"
          className="form-control align-middle d-inline-block react-bootstrap-table2-search-header"
          placeholder={t('common.search')}
          // add custom onChange handler
          onChange={e => {
            const filter = e.target.value;
            const lowercaseFilter = filter.toLowerCase().trim();
            const { userDevices=[] } = this.props;
            const annotatedDevices = userDevices.map(device => {
              return {
                ...device,
                active: `${
                  device.equipment_name
                } ${
                  device.site_name
                } ${
                  device.site_subarea_name
                } ${
                  !device.archived ? 'active' : 'archived'
                }`.toLowerCase().includes(lowercaseFilter),
              };
            });
            this.setState({
              filter,
              annotatedDevices,
            });
          }}
        />
      </label>
    );
  };

  renderHeader = props => {
    const { submit } = this.state;
    const { t } = this.props;
    return (
      <Toolbar
        searchable
        renderItemCount={this.renderItemCount}
        renderSearchBar={this.renderSearchBar}
        title={t('user.User_Equipment')}
        loading={submit.submittedAt}
        lastFetch={submit.succeededAt}
        error={submit.error}
        tableProps={props}
      />
    );
  };

  render() {
    const {
      userIsNew,
      userToEdit,
      userDevices = [],
      t
    } = this.props;

    // todo: fix this with a selector after selectors are better refactored around userIds
    if (userToEdit && userToEdit.user_type !== 'User') {
      return (
        <Alert variant="warning">
          <p className="mb-0">
            <IoIosCube size="1.4em"/> {t('user.Equipment_access_warning')}
          </p>
        </Alert>
      );
    }

    // catch empty exceptions
    if (userIsNew || !userToEdit) {
      return null;
    }

    const {
      submit,
      filter = '',
      annotatedDevices = userDevices.map(device => ({ ...device, active: true })),
    } = this.state;
    // Check type as e.g. equipment/columns.js exports mm2 where text/tooltip are React components.
    const columns = userDevices.find(({ archived }) => archived) // if at least one device is marked as archived, show the archive status column
      ? columnsWithArchivedStatus.map(col => ({...col, text: (typeof col.text === 'string' ? t(formatTableColumn(col.text)) : col.text)}))
      : columnsWithoutArchivedStatus.map(col => ({...col, text: (typeof col.text === 'string' ? t(formatTableColumn(col.text)) : col.text)}));

    return <Form
      id="edit-user-form"
      className="form-container"
      onSubmit={ this.handleSubmit }
    >
      <Table
        rowClasses="text-muted"
        rowStyle={filter ? { backgroundColor: 'rgba(0,0,0,.05)' } : {}}
        keyField="device_id"
        submit={submit}
        renderHeader={this.renderHeader}
        data={annotatedDevices}
        defaultSorted={defaultSorted}
        columns={columns}
        noDataIndication={() => 'No Devices'}
        loading={submit.submittedAt}
        striped={!filter}
        selectRow={{
          mode: 'checkbox',
          hideSelectColumn: true,
          selected: filter ? annotatedDevices.reduce((acc, { device_id, active }) => {
            if (active) {
              acc.push(device_id);
            }
            return acc;
          }, []) : userDevices.map(({ device_id }) => device_id),
          classes: "text-reset",
          bgColor: filter ? 'white' : undefined,
        }}
      />
      <Form.Group className="text-right mt-3 mb-chat-widget">
        <Button variant="success" type="submit" size="lg">
          {userIsNew ? t('Create') : t('Update')}
        </Button>
      </Form.Group>
    </Form>;
  }
}


const mapStateToProps = (state, { userIsNew, userIsSelf, userId }) => {
  return {
    userIsNew,
    userIsSelf,
    userToEdit: getUser(state, userId),
    userDevices: getUserDevices(state, userId),
  };
};
const mapDispatchToProps = { fetchUserDevices, submitUserDevices };

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditUserDevices));
