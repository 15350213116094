
import { Fragment } from 'react';
// we use axios as fetch does not support upload progress events
import axios from 'axios';
import { t } from 'i18next';

import { API_TIMEOUT_MS, NOT_LOGGED_IN, TIMEOUT_EXCEEDED } from '../constants';
import { getUserCacheNamespace, API_ABORT, generateTimeoutExceededText } from './utils';
import * as ACTION_TYPES from '../modules/user/types/ActionTypes';
import { addToast } from '../components/Toaster';
import log from './log';

import { getLocalAnonymousPreference, getUser, getUserToken } from '../modules/user/selectors';

export const CALL_API = 'api/CALL';

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const ALLOWED_METHODS = ['get', 'post', 'put', 'delete'];

export const isApiCancelled = err => err?.message === 'canceled';

function callApi(method, url, token, { params, data, state }={}, abortControllerId) {
  if (ALLOWED_METHODS.indexOf(method) < 0) {
    throw new Error(`Method not allowed: ${method}`);
  }

  // add abort controller to middleware state
  (typeof AbortController !== 'undefined' && abortControllerId && !abortControllersById[abortControllerId]) &&
    (abortControllersById[abortControllerId] = new AbortController());

  const requestConfig = {
    method,
    baseURL: (process.env.REACT_APP_SWITCHABLE_API && getLocalAnonymousPreference(state, 'api_url')) || API_BASE_URL,
    url,
    params,
    data,
    // put the header auth in!
    headers: token && {
      'Authorization': token,
    },
    responseType: 'json',
    // set a reasonable timeout time otherwise this
    // just spins forever
    timeout: API_TIMEOUT_MS,
    signal: abortControllersById[abortControllerId]?.signal,
  };

  return axios.request(requestConfig).finally(() => {
    if (abortControllerId && abortControllersById[abortControllerId]) {
      // Clean up aobrt controller.
      delete abortControllersById[abortControllerId];
    }
  });
}

// internal middleware abort controller state not persisted in redux state
// typeof { [id: string]: AbortController }
const abortControllersById = {};

export default store => next => async action => {

  // catch abort actions
  if (action.type === API_ABORT) {
    if (action.abortControllerId && abortControllersById[action.abortControllerId]) {
      // abort the controller by ID
      abortControllersById[action.abortControllerId].abort();
    }
  }

  if (action.type !== CALL_API) {
    return next(action);
  }

  const {
    method,
    endpoint,
    userMustBeAuthenticated = true,
    readCache = true,
    writeCache = true,
    userCacheNamespace,
    params,
    data,
    requestAction,
    successAction,
    errorAction,
    successToast,
    errorToast,
    abortControllerId,
    errorDetails = null,
  } = action;
  const request = `${`${method}`.toUpperCase()}${endpoint}`;
  const state = store.getState();
  const user = getUser(state);
  const userId = user && user.id;
  const userToken = getUserToken(state);

  requestAction && store.dispatch(requestAction);

  // get cached response if appropriate
  const cache = userCacheNamespace && userId
    && await getUserCacheNamespace(userId, userCacheNamespace);
  const cachedResponse = cache && readCache && await cache.match(endpoint);

  const [error, response] = await new Promise(function getResponse(resolve) {

    return cachedResponse
      // this returns a fetch Response body or error
      ? cachedResponse.json()
        .then(response => resolve([null, response]))
        .catch(error => resolve([error]))
      // this returns an Axios response body or error
      : callApi(method, endpoint, userToken, { params, data, state }, abortControllerId)
        .then(response => resolve([null, response]))
        .catch(error => resolve([error, error && error.response]));
  });

  return new Promise(async function processResponse(resolve, reject) {

    // do not call reducers if the user has no authentication after request
    if (userMustBeAuthenticated && !getUserToken(store.getState())) {
      return reject(new Error('Not logged in', { cause: NOT_LOGGED_IN }));
    }

    // process successful response
    if (!error && response) {
      try {
        // display toast if it is whitelisted with a message
        if (successToast) {
          // add toast with a default success variant
          addToast({
            variant: 'success',
            ...(typeof successToast === 'object' ? successToast : { header: `${successToast}` }),
          });
        }

        next(successAction && {
          ...successAction,
          request: data,
          response: response.data,
          responseHeaders: response.headers,
        });
      }
      catch(e) {
        // log called reducer error
        log.error(new Error('API action success error'), { request }, { err: e });
      }
      if (cache && writeCache && !cachedResponse) {
        const { data, headers } = response;
        cache.put(endpoint, new Response(
          JSON.stringify({ data }), { headers })
        );
      }
      return resolve({ ...response, cache });
    }

    // process error
    if (error) {
      // If the error is triggered by aborting API call.
      const isCancelError = isApiCancelled(error);
      if (errorDetails) {
        error.errorDetails = errorDetails;
      }

      try {
        // get response from error
        const response = (error && error.response) || {};

        // if no CORS headers are seen:
        // the network may have failed or the user is unauthenticated
        if ((!response.status && !isCancelError) && (
          // is the user unauthenticated? check our dedicated route
          // get response (2XX) or error response (4XX, 5XX, null)
          await callApi('get', '/checkauth', userToken, { state })
            // allow error responses to count as responses
            .catch(e => e && e.response)
            // check all responses for 403s
            .then(response => response && response.status === 403)
        )) {

          // if the user is explicitly unauthenticated then log them out
          next({ type: ACTION_TYPES.TOKEN_EXPIRED });
          // add expiration toast
          addToast({
            variant: 'danger',
            header: t('toasts.session-has-expired', 'Your session has expired. Log in again to continue.'),
          });

        }
        else {

          // get message passed from frontend (error.message) or backend (data.message)
          const message = (response.data && response.data.message) || error.message;

          // display a toast if they are not blacklisted from this response
          if (errorToast !== false && !isCancelError) {
            // display toast if given
            if (errorToast) {
              // add given toast with a default header
              addToast({
                variant: 'danger',
                header: message,
                ...(typeof errorToast === 'object' ? errorToast : { header: `${errorToast}` }),
              });
            }
            // display validation error if it exists
            else if (response.data && response.data.validation_errors) {
              // display each validation error as its own toast
              Object.entries(response.data.validation_errors).forEach(([field, fieldErrors]) => {
                fieldErrors.forEach(fieldError => {
                  addToast({
                    variant: 'danger',
                    header: <span>{field}: {errorToString(fieldError)}</span>,
                    timeout: 10000, // let validation errors hang around for a longer while
                  });
                });
              });
            }
            // display default error
            else {
              // This error happens often enough that it's an annoying pop up. The error will still
              // show where the loading icon turns in to an exclamation mark. But no annoying Toast.
              const timeoutExceededText = generateTimeoutExceededText(TIMEOUT_EXCEEDED, API_TIMEOUT_MS);
              if (error?.message !== timeoutExceededText) {
                // add toast with a default header
                addToast({
                  variant: 'danger',
                  header: message,
                });
              }
            }
          }

          next(errorAction && {
            ...errorAction,
            request,
            response: response.status ? `${message} (Code: ${response.status})` : message,
            responseHeaders: response.headers,
          });
        }

      }
      catch(e) {
        // log called reducer error
        log.error(new Error('API action failure error'), { request }, { err: e });
      }
      return reject(error);
    }

    // send a default error if neither an error or response were seen
    return reject(new Error('No response'));
  });
};

// print generic errors nicely
// should be able to take {users: [{4: [{rights: ["empty values not allowed"]}]}]}
// and look like:
// ------------------------------------------
// |  users:                                |
// |    - rights: empty values not allowed  |
// ------------------------------------------
function errorToString(error) {
  // allow deeply nested errors to be readable by the end user
  if (typeof error === 'object') {
    // this is a bit messy but gets the job done
    return Object.entries(error).map(([key, value], index) => (
      // if the key is a string, add a new indent
      isNaN(Number(key)) ? (
        <ul key={key} className="mb-1">
          <li>{key}: {errorToString(value)}</li>
        </ul>
      ) : (
        <Fragment key={key}>
          {index > 0 && <span>, </span>}
          {errorToString(value)}
        </Fragment>
      )
    ));
  }
  // return flat variable to template
  else {
    return error;
  }
}
