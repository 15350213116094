import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectFilter } from 'react-bootstrap-table2-filter';
import { useTranslation } from 'react-i18next';

import {
  IoIosCog,
  IoIosSchool,
  IoIosNotifications,
  IoIosNotificationsOff,
  IoIosBatteryCharging,
  IoIosWifi,
  IoIosTime,
} from 'react-icons/io';

import moment from 'moment';

import {
  headerFormatter,
  inlineHeaderFormatter,
  textFormatter,
  nullFormatter,
  percentageNumberFormatter,
  zeroDigitsNumberFormatter,
  twoDigitsNumberFormatter,
  calibrationFormatter,
  relativeDateFormatter,
  relativeDateFilter,
  batteryScoreFormatter,
  wifiScoreFormatter,
} from '../../components/table/formatters';

import {
  numericSortFunc,
  stringSortFunc,
  stringSortValueWithHumanNumbersFunc,
  booleanSortFunc,
  timeSortFunc,
  statusSortFunc,
} from '../../components/table/utils';

import { getPlaceholderImageForDevice, getSensorName } from './utils';

import VolumeImage from '../../modules/alarm/VolumeImage';
import ToneImage from '../../modules/alarm/ToneImage';
import TemperatureImage from '../../modules/alarm/TemperatureImage';

import UserPicture from '../user/components/UserPicture';
import Bar from '../../components/Bar';
import RunningStatus from './components/RunningStatus';
import StatusIndicator from './components/StatusIndicator';
import StatusSummaryIndicator from './components/StatusSummaryIndicator';
import {
  getStaleValue,
  StaleIndicatorBadge,
  ArchivedIndicatorBadge,
} from './components/StatusIndicatorBadges';
import StatusFilter from './components/StatusFilter';
import EquipmentNameLink from './components/EquipmentNameLink';
import {
  getOrganisationMm2AvailablePreference,
  getOrganisationRmsAvailablePreference,
} from '../organisation/selectors';
import { formatCurrency, formatNumber } from '../../components/lib/utils';

// export connected columns
export { column as rms } from './columns/rms';
export { column as temperature } from './columns/temperature';
export { column as vibrationRms } from './columns/vibrationRms';

// todo: remove rms + mm2 -> DASH-1018
function Mm2Title({ rmsAvailable, mm2Available }) {
  const { t } = useTranslation();
  return rmsAvailable && mm2Available
    ? t('common.vibration-rms-filtered')
    : t('common.vibration-rms');
}

// todo: remove rms + mm2 -> DASH-1018
// connect the mm2 title to show to the currently logged in user
const ConnectedMm2Title = connect(state => ({
  mm2Available: getOrganisationMm2AvailablePreference(state),
  rmsAvailable: getOrganisationRmsAvailablePreference(state),
}))(Mm2Title);

// export constant columns
// todo: remove rms + mm2 -> DASH-1018
export const mm2 = {
  // Note that using t, here for '__csvHeaderText', will fail as it is accessing i18next before it's
  // ready. Instead, we put the translation path here and pass that to the hook in the component.
  // Not a nice solution but it works until I find a better one.
  dataField: 'rms2',
  text: 'Vibration (RMS Filtered)',
  __csvHeaderText: 'common.vibration-rms-filtered', // See note above
  tooltip: <ConnectedMm2Title />,
  headerFormatter: inlineHeaderFormatter,
  formatter: twoDigitsNumberFormatter,
  filterValue: twoDigitsNumberFormatter,
  sort: true,
  sortFunc: numericSortFunc,
  __canChangeVisibility: true,
};

// export constant columns
export const archived = {
  dataField: 'archived',
  text: 'Archived',
  headerFormatter,
  formatter: (value, device) => (
    <ArchivedIndicatorBadge device={device} />
  ),
  filterValue: archived => !archived ? 'active' : 'archived',
  sort: true,
};

export const status = {
  dataField: 'status',
  text: 'Status',
  headerFormatter,
  formatter: value => (
    <span className="text-nowrap">
      <StatusIndicator conditionValue={value}/>
    </span>
  ),
  filterValue: nullFormatter,
  sort: true,
  sortFunc: numericSortFunc,
};

export const statusSummary = {
  dataField: 'status_summary',
  text: 'Status',
  headerFormatter: inlineHeaderFormatter,
  formatter: value => (
    <span className="text-nowrap">
      <StatusSummaryIndicator status={value} />
    </span>
  ),
  sort: true,
  sortFunc: statusSortFunc,
  filter: selectFilter({
    onFilter: (filterVal, data) => {
      return data.filter(oneData => filterVal.includes(oneData.status_summary?.caption));
    }
  }),
  filterRenderer: (onFilter, column) => <StatusFilter onFilter={ onFilter } column={ column } />,
  __canChangeVisibility: false,
  __csvTextFormatter: value => value?.caption || '',
};

export const statusCaption = {
  dataField: 'status_caption',
  text: 'Caption',
  hidden: true,
};

export const calibration = {
  dataField: 'calibration',
  text: 'Learning progress (%)',
  icon: <IoIosSchool size="1.4em" />,
  headerFormatter: inlineHeaderFormatter,
  formatter: calibrationFormatter,
  filterValue: calibrationFormatter,
  sort: true,
  sortFunc: numericSortFunc,
  __canChangeVisibility: false,
};

export const siteName = {
  dataField: 'site_name',
  text: 'Site',
  headerFormatter,
  formatter: textFormatter,
  sort: true,
  sortFunc: stringSortFunc,
  sortValue: stringSortValueWithHumanNumbersFunc,
};

export const subAreaName = {
  dataField: 'sub_area_name', // The field name mapped from API
  text: 'Sub-area',
  headerFormatter: inlineHeaderFormatter,
  formatter: textFormatter,
  sort: true,
  sortFunc: stringSortFunc,
  sortValue: stringSortValueWithHumanNumbersFunc,
};

export const siteSubareaName = {
  ...subAreaName,
  dataField: 'site_subarea_name', // API's original field name
};

export const equipmentName = {
  dataField: 'equipment_name',
  text: 'Equipment Name',
  headerFormatter: inlineHeaderFormatter,
  // when returned in relations device id may be keyed under device_id
  formatter: (value, { device_id, id, sensor_type }) => (
    <Link to={`/equipment/${device_id || id}/${sensor_type === 'fitpower' ? 'fitpower' : ''}`} onClick={(e) => e.stopPropagation()}>{value || "N/A"}</Link>
  ),
  filterValue: textFormatter,
  sort: true,
  sortFunc: stringSortFunc,
  sortValue: stringSortValueWithHumanNumbersFunc,
  __canChangeVisibility: false,
};

export const equipmentNameLinkExtra = {
  ...equipmentName,
  // when returned in relations device id may be keyed under device_id
  formatter: (value, { device_id, id, sensor_type }, forIndex, formatExtraData) => (
    <EquipmentNameLink
      to={`/equipment/${device_id || id}/${sensor_type === 'fitpower' ? 'fitpower' : ''}`}
      formatExtraData={formatExtraData}
    >
      {value || 'N/A'}
    </EquipmentNameLink>
  ),
};

export const equipmentNumber = {
  dataField: 'equipment_number',
  text: 'Equipment Number',
  headerFormatter: inlineHeaderFormatter,
  // when returned in relations device id may be keyed under device_id
  formatter: (value, { device_id, id, sensor_type }) => (
    <Link to={`/equipment/${device_id || id}/${sensor_type === 'fitpower' ? 'fitpower' : ''}`} onClick={(e) => e.stopPropagation()}>{value || "N/A"}</Link>
  ),
  filterValue: textFormatter,
  sort: true,
  sortFunc: stringSortFunc,
  sortValue: stringSortValueWithHumanNumbersFunc,
  __canChangeVisibility: true,
};

export const staleness = {
  dataField: 'staleness',
  icon: <IoIosTime size="1.4em" />,
  text: 'Data age status',
  headerFormatter,
  formatter: (ignore, device) => (
    <StaleIndicatorBadge device={device} />
  ),
  filterValue: nullFormatter,
  sort: true,
  sortValue: (ignore, device) => getStaleValue(device),
};

export const stalenessExpanded = {
  ...staleness,
  formatter: (ignore, device) => (
    <StaleIndicatorBadge device={device} expanded />
  ),
};

export const conditionOverall = {
  dataField: 'condition_overall',
  text: 'Condition',
  tooltip: 'Condition (AI computed)',
  headerFormatter,
  formatter: value => (
    <Bar conditionValue={value} />
  ),
  filterValue: nullFormatter,
  sort: true,
  sortFunc: numericSortFunc,
  __canChangeVisibility: false,
};

export const conditionIndicated = {
  ...conditionOverall,
  dataField: 'condition_indicated',
};

export const equipmentState = {
  dataField: "state",
  text: "State",
  sort: true,
};

export const utilisationMonth = {
  dataField: 'utilisation_month',
  text: 'Utilisation (4 weeks)',
  icon: <IoIosCog size="1.4em" />,
  headerFormatter: inlineHeaderFormatter,
  formatter: percentageNumberFormatter,
  filterValue: percentageNumberFormatter,
  sort: true,
  sortFunc: numericSortFunc,
};

export const running = {
  dataField: 'running',
  text: 'Running status',
  icon: <RunningStatus value={false} />,
  headerFormatter: inlineHeaderFormatter,
  formatter: value => (
    <RunningStatus value={value} />
  ),
  // allow searching the word 'running'
  filterValue: running => running === true ? 'running' : null,
  sort: true,
  sortFunc: booleanSortFunc,
  __canChangeVisibility: false,
};

export const muteAdvisoryFor = {
  dataField: 'mute_advisory_for',
  text: 'Notifications status',
  icon: <IoIosNotifications size="1.4em" />,
  headerFormatter: inlineHeaderFormatter,
  formatter: value => (
    value === 0
      ? <IoIosNotifications size="1.4em" />
      : <IoIosNotificationsOff size="1.4em" />
  ),
  // allow searching the word 'muted'
  filterValue: value => value !== 0 ? 'muted' : null,
  sort: true,
  sortFunc: booleanSortFunc,
};

export const note = {
  dataField: 'note',
  text: 'Notes',
  headerFormatter,
  formatter: textFormatter,
  sort: true,
  sortFunc: stringSortFunc,
};

export const serial = {
  dataField: 'serial',
  text: 'MAC address',
  headerFormatter,
  formatter:  (value) => (
    <>{value || "N/A"}</>
  ),
  filterValue: textFormatter,
  sort: true,
  sortFunc: stringSortFunc,
  sortValue: stringSortValueWithHumanNumbersFunc,
};

const fmIconStyle = {
  width: '1.3em',
  verticalAlign: '-20%',
  display: 'inline-block',
};

export const type = {
  dataField: 'fitmachine_type',
  text: 'Type',
  headerFormatter,
  // when returned in relations device id may be keyed under device_id
  formatter: (value, device={}) => {
    return (
      <span className="text-nowrap">
        <img
          src={getPlaceholderImageForDevice(device)}
          style={fmIconStyle}
          alt={value || "FitMachine"}
        /> {value || getSensorName(device)}
      </span>
    );
  },
  filterValue: textFormatter,
  sort: true,
  sortFunc: stringSortFunc,
  sortValue: stringSortValueWithHumanNumbersFunc,
};

export const organisationName = {
  dataField: 'organisation_name',
  text: 'Organisation',
  headerFormatter,
  formatter: (value, { organisation_id }) => (
    <Link to={`/organisations/${organisation_id}`} onClick={(e) => e.stopPropagation()}>{value || "N/A"}</Link>
  ),
  filterValue: textFormatter,
  sort: true,
  sortFunc: stringSortFunc,
};

export const onboardedName = {
  dataField: 'onboarded_name',
  text: 'Onboarded by',
  headerFormatter,
  formatter: (value, { onboarded_by }) => onboarded_by && value ? (
    <Fragment>
      <Link to={`/users/${onboarded_by}`} onClick={(e) => e.stopPropagation()}><UserPicture userId={onboarded_by}/></Link>
      {' '}
      <Link to={`/users/${onboarded_by}`} onClick={(e) => e.stopPropagation()}>{value || "N/A"}</Link>
    </Fragment>
  ) : "N/A",
  filterValue: textFormatter,
  sort: true,
  sortFunc: stringSortFunc,
};

export const fmLastHeardHoursWarning = 24;
export const fmLastHeardHoursDanger = 48;
export const fmLastHeard = {
  dataField: 'fitmachine_last_heard',
  text: 'Last data processed',
  headerFormatter,
  formatter: relativeDateFormatter,
  filterValue: relativeDateFilter,
  sort: true,
  sortFunc: timeSortFunc,
};

export const fmGatewayLastHeard = {
  dataField: '_related.gateway.last_heard',
  text: 'Last Access',
  headerFormatter,
  formatter: relativeDateFormatter,
  filterValue: relativeDateFilter,
  sort: true,
  sortFunc: timeSortFunc,
};

export const batteryVoltage = {
  dataField: 'battery_voltage',
  text: 'Battery voltage',
  tooltip: 'Battery voltage (V)',
  icon: <IoIosBatteryCharging size="30" />,
  headerFormatter,
  formatter: twoDigitsNumberFormatter,
  filterValue: twoDigitsNumberFormatter,
  sort: true,
  sortFunc: numericSortFunc,
};

export const batteryScore = {
  ...batteryVoltage,
  dataField: 'battery_score',
  tooltip: 'Battery health',
  formatter: (value, row) => (<div className="d-flex">{batteryScoreFormatter(value, row)}</div>)
};

export const wifiSignal = {
  dataField: 'wifi_signal',
  text: 'WiFi signal',
  tooltip: 'WiFi signal',
  icon: <IoIosWifi size="1.4em" />,
  headerFormatter,
  formatter: zeroDigitsNumberFormatter,
  filterValue: zeroDigitsNumberFormatter,
  sort: true,
  sortFunc: numericSortFunc,
};

export const wifiScore = {
  ...wifiSignal,
  tooltip: 'Network health',
  dataField: 'wifi_score',
  formatter: (value, row) => (<div className="d-flex">{wifiScoreFormatter(value, row)}</div>)
};

export const conditionVolume = {
  dataField: 'condition_volume',
  text: 'AI Volume',
  tooltip: 'Volume (AI computed)',
  headerFormatter,
  formatter: (ignore, { equipment_iso_class, rms }) => (
    <VolumeImage rms_value={rms} isoClass={equipment_iso_class}/>
  ),
  filterValue: nullFormatter,
  sort: true,
  sortFunc: numericSortFunc,
};

export const conditionVibration = {
  dataField: 'condition_vibration',
  text: 'AI Tone',
  tooltip: 'Tone (AI computed)',
  headerFormatter,
  formatter: value => (
    <ToneImage vibration_condition={value} />
  ),
  filterValue: nullFormatter,
  sort: true,
  sortFunc: numericSortFunc,
};

export const conditionTemperature = {
  dataField: 'condition_temperature',
  text: 'AI Temperature',
  tooltip: 'Temperature (AI computed)',
  headerFormatter,
  formatter: value => (
    <TemperatureImage temperature_condition={value} />
  ),
  filterValue: nullFormatter,
  sort: true,
  sortFunc: numericSortFunc,
};

export const alarmTime = {
  dataField: 'alarm_time',
  text: 'Date',
  formatter:(value, {alarm_id}) => (
    <Link to={`/alarms/${alarm_id}/impact`}>
      {relativeDateFormatter(moment(value).local()) || 'N/A'}
    </Link>
  ),
  sort: true,
  sortFunc: timeSortFunc,
};

export const reason = {
  dataField: 'reason',
  text: 'Summary'
};

export const lostHours = {
  dataField: 'lost_hours',
  text: 'Hours Lost',
  align: 'right',
  headerAlign: 'right',
  formatter: cell => formatNumber(cell),
  sort: true,
};

export const avoidedHours = {
  dataField: 'avoided_hours',
  text: 'Avoided Hours Lost',
  align: 'right',
  headerAlign: 'right',
  formatter: cell => formatNumber(cell),
  sort: true,
};

// For event costs, avoided costs and total reduction on group level, their currency is included in API.
// But on equipment level, their currency is passed from component.
export const groupEventCosts = {
  dataField: 'event_costs',
  text: 'Event Cost',
  align: 'right',
  headerAlign: 'right',
  formatter: (cell, row) => <span className="text-nowrap">{formatCurrency(cell, row.locale?.currency?.currency || 'AUD')}</span>,
  sort: true,
  classes: 'text-danger',
};

export const groupAvoidedCosts = {
  dataField: 'avoided_costs',
  text: 'Avoided Cost',
  align: 'right',
  headerAlign: 'right',
  formatter: (cell, row) => <span className="text-nowrap">{formatCurrency(cell, row.locale?.currency?.currency || 'AUD')}</span>,
  sort: true,
};

export const groupTotalReduction = {
  dataField: 'total',
  text: 'Event Impact',
  align: 'right',
  headerAlign: 'right',
  formatter: (cell, row) => <span className="text-nowrap">{formatCurrency(cell, row.locale?.currency?.currency || 'AUD')}</span>,
  sort: true,
  classes: (cell) => cell < 0 ? 'text-danger' : '',
};

export const getEquipmentEventCostsColumn = (currency) => ({
  ...groupEventCosts,
  formatter: cell => <span className="text-nowrap">{formatCurrency(cell, currency || 'AUD')}</span>
});
export const getEquipmentAvoidedCostsColumn = (currency) => ({
  ...groupAvoidedCosts,
  formatter: cell => <span className="text-nowrap">{formatCurrency(cell, currency || 'AUD')}</span>
});
export const getEquipmentTotalReductionColumn = (currency) => ({
  ...groupTotalReduction,
  formatter: cell => <span className="text-nowrap">{formatCurrency(cell, currency || 'AUD')}</span>
});

export const equipmentLink = {
  dataField: 'equipment_name',
  text: 'Equipment',
  sort: true,
  formatter: (cell, row) => <Link to={`/equipment/${row.device_id}`}>{cell}</Link>,
};