// MOVUS main site
export const privacyUrl = 'https://movus.com.au/privacy-policy';

// MOVUS Knowledge Base
export const knowledgeBaseRootUrl = 'https://learn.movus.com.au/knowledge';
export const rcoUrl = 'https://learn.movus.com.au/knowledge/adjusting-the-running-cut-off';

// MOVUS Dashboard
export const productionDashboardUrl = 'https://app.movus.com.au';

// Email addresses
export const productEmailAddress = 'product@movus.com.au';
