
import React, { Fragment } from 'react';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';

import {
  API_TIMEOUT_MS,
  HTTP_FORBIDDEN,
  HTTP_GATEWAY_TIMEOUT,
  IGNORE_EVENTS_SUMMARY,
  NETWORK_ERROR,
  NOT_LOGGED_IN,
  POLLING,
  TIMEOUT_EXCEEDED,
} from './constants';
import { generateTimeoutExceededText } from './lib/utils';

const configuration = {};
configuration.autoNotify = true;
configuration.appVersion = process.env.REACT_APP_MACHINECLOUD_DASH_VERSION;
configuration.releaseStage = process.env.REACT_APP_BUGSNAG_RELEASE_STAGE;
configuration.appType = 'client';
configuration.beforeSend = async report => {
  // wait for each callback to complete in order
  await callbacks.reduceRight(async (report, callback) => {
    await callback(report);
    const notLoggedIn = report?.originalError?.cause === NOT_LOGGED_IN;
    const forbidden = report?.originalError?.response?.status === HTTP_FORBIDDEN;
    const gatewayTimeout = report?.originalError?.response?.status === HTTP_GATEWAY_TIMEOUT;
    const networkError = report?.originalError?.request?.status === NETWORK_ERROR;
    const timeoutExceededText = generateTimeoutExceededText(TIMEOUT_EXCEEDED, API_TIMEOUT_MS);
    const timeoutExceeded = report?.originalError?.message === timeoutExceededText;

    // Adds a custom error class, grouping and message
    // https://docs.bugsnag.com/platforms/javascript/legacy/customizing-error-reports/#the-report-object
    if (report?.originalError?.errorDetails) {
      const originalMessage = report?.originalError?.message;
      const customErrorDetails = report.originalError.errorDetails;
      const extendedMessage = `${originalMessage} - ${customErrorDetails.message}`;
      report.errorClass = customErrorDetails.class;
      report.groupingHash = report.errorClass;
      report.errorMessage = extendedMessage;
      // https://movusoz.atlassian.net/browse/DASH-1165
      if (customErrorDetails.extra === IGNORE_EVENTS_SUMMARY && (gatewayTimeout || networkError)) report.ignore();
      if (customErrorDetails.extra === POLLING && networkError) report.ignore();
    }

    if (notLoggedIn || forbidden || gatewayTimeout || timeoutExceeded) report.ignore();
    return report;
  }, report);
};

// export callback registers to be consistent with the way the react-native implementation works
const callbacks = [];
export const registerBeforeSendCallback = cb => callbacks.push(cb);
export const unregisterBeforeSendCallback = cb => callbacks.filter(item => item === cb);

const __DEV__ = process.env.NODE_ENV !== 'production';

const getBugsnagClient = () => {
  if (process.env.REACT_APP_BUGSNAG_API_KEY) {
    const bugsnagClient = bugsnag({
      apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
      ...configuration,
    });
    bugsnagClient.use(bugsnagReact, React);
    // make client setUser consistent with the react-native version
    bugsnagClient.setUser = (id, name, email) => {
      // apply new settings over old settings
      bugsnagClient.user = { id, name, email };
    };
    bugsnagClient.clearUser = () => {
      // reset user to original state found in the bugsnagClient
      bugsnagClient.user = {};
    };
    return bugsnagClient;
  }

  // else return a mock
  const bugsnagClientMock = {
    setUser: () => {},
    clearUser: () => {},
    leaveBreadcrumb: () => {},
    // getPlugin returns an error boundary wrapper
    // as this can't be created, just wrap with a Fragment
    getPlugin: () => Fragment,
    // eslint-disable-next-line no-console
    notify: (...args) => console.log('Bugsnag notify call mock', args),
  };

  return bugsnagClientMock;
};

const bugsnagClient = getBugsnagClient();

export default bugsnagClient;

/*
 * bugsnag uses the following keys to display information in their dashboard
 * - stacktrace
 * - threads
 * - breadcrumbs
 * - app
 * - device
 * - user
 * - error
 * - context
 * so we must ensure we don't use these keys inside our metadata
 */

// this config sets what metadata information can be seen in Bugsnage
// and also sets the order that the keys are displayed
// allowing the Bugsnag UI to have a consistent information layout
const allowedKeysByMetadataNamespace = {
  app: ['codeBundleId'], // is included by bugsnag before the report is created
  organisation: ['id', 'name', 'sub_domain'],
  device: ['id', 'serial', 'hardwareId'], // renamed as 'FitMachine' in BugSnag to avoid name clash
  // a simple way to add an error, and have it's context copied over
  err: [ // renamed as 'Error Origin'
    'stack',
    'message',
    // add axios things
    'response.data',
    'response.status',
    // include specific things in local development
    ...__DEV__ ? [
      'config.data',
    ] : [],
  ],
};

const blacklistedKeys = [
  // axios object stuff
  'headers',
  '_lowerCaseResponseHeaders',
  'responseHeaders',
];

function removeBlacklistedKeys(obj) {
  // process other data types: return given value
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }
  // process arrays: keep traversing down
  else if (Array.isArray(obj)) {
    return obj.map(removeBlacklistedKeys);
  }
  // process normal objects and class instances: remove blacklisted keys
  else if (Object.prototype.toString.call(obj) === '[object Object]') {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      if (!blacklistedKeys.includes(key)) {
        // recurse on values (simple values exit quickly)
        acc[key] = removeBlacklistedKeys(value);
      }
      return acc;
    }, {});
  }
  // do not process tricky objects, eg. typeof new Date() === 'object'
  else {
    return obj;
  }
}

// mostly copied from lodash.get
function objectGet(object, pathString) {
  const path = pathString.split('.');
  const length = path.length;
  let index = 0;
  // != instead of !== is important here, particularly if object === undefined
  while (object != null && index < length) {
    object = object[path[index]];
    index += 1;
  }
  return (index && index === length) ? object : undefined;
}

// scrub sensitive information from arbitrary objects
export function scrubContext(obj, { whitelist=true, blacklist=true }={}) {
  // obj may be null
  if (typeof obj !== 'object') {
    return {};
  }
  // loop through each namespace to whitelist keys
  const whitelistedObj = whitelist
    ? Object.entries(allowedKeysByMetadataNamespace)
      .reduce((acc, [namespace, whitelistedKeys]) => {
        // pick out relevant props in each namespace object
        if (typeof obj[namespace] === 'object') {
          const props = whitelistedKeys.reduce((acc, key) => {
            const value = objectGet(obj[namespace], key);
            if (value !== undefined) {
              acc[key] = value;
            }
            return acc;
          }, {});
          // add namespace to obj if any props are found
          if (Object.keys(props).length > 0) {
            acc[namespace] = props;
          }
        }
        return acc;
      }, {})
    : obj;

  return blacklist
    ? removeBlacklistedKeys(whitelistedObj)
    : whitelistedObj;
}

const defaultAllowedTypes = [
  'boolean',
  'number',
  'bigint',
  'symbol',
  'string',
];

export function scrubObjectValues(obj={}, allowedTypes=defaultAllowedTypes) {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (allowedTypes.includes(typeof key)) {
      acc[key] = value;
    }
    return acc;
  }, {});
}
