import log from './log';
import React from 'react';
import { Link } from 'react-router-dom';
import { t } from 'i18next';

import { POLLING } from '../constants';
import { addToast } from '../components/Toaster';
import { noBreakingSpaces } from '../components/Table';

export const MAC_ADDRESS_REGEX = '([0-9A-F]{2}:){5}([0-9A-F]{2})';

export function capitaliseFirstChar(text='') {
  return text.substr(0, 1).toUpperCase() + text.substr(1);
}

export function isFloatEqual(a, b) {
  // convert both numbers to floating points before comparing
  // if one is the conversion to and from of the other then this should be equal:
  // e.g. 0.9 !== 0.9*0.01/0.01 (0.9000000000000001)
  // but: Math.fround(0.9) === Math.fround(0.9*0.01/0.01)
  // as both are 0.8999999761581421 as floating points
  // use parseFloat to deal with empty strings consistently (NaN instead of 0)
  return Math.fround(parseFloat(a)) === Math.fround(parseFloat(b));
}

// from https://davidwalsh.name/javascript-debounce-function
// from underscore.js
// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export function debounce(func, wait, immediate) {
  let timeout;
  return function(...args) {
    const later = () => {
      timeout = null;
      if (!immediate) {
        func.apply(this, args);
      }
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {
      func.apply(this, args);
    }
  };
};

/*
 * Takes a form submit event,
 * cancels the default submission,
 * and returns the form values as a keyed object
 * with all the form's input values
 * {
 *   [input.name]: input.value
 * }
 */
export function getFormValues(e, form = e.target.form || e.target) {
  e.preventDefault();
  e.stopPropagation();
  // if there are no form elements this should return undefined
  // (because the target is probably not a form)
  return form.elements && [...form.elements].reduce((acc, element ) => {
    const { name, value, type } = element;
    if (name) {
      if (type === "checkbox") {
        acc[name] = !!element.checked; // checkbox values should be boolean
      } else if (type === "radio") {
        if (element.checked) {
          acc[name] = element.value; // radio values should be strings
        }
      } else if (element.localName === "select" && element.multiple) {
        acc[name] = [...element.selectedOptions].map(option => option.value);
      } else {
        acc[name] = value;
      }
    }
    return acc;
  }, {});
}

/**
 * Accept form reference and clear its value.
 * For now, only handles text and textarea.
 * If there's more input to clear, add its type here.
 */
export function resetFormValues(formRef) {
  const formElements = formRef?.current;
  if(!formElements || formElements.length === 0) return;
  for(const el of formElements) {
    if(el.type === 'text' || el.type === 'textarea') {
      el.value = '';
    }
  }
}

/*
 * get or create and get a cache namespaced under 'movus':
 * eg getUserCacheNamespace(1, 'device/1')
 */
export async function getUserCacheNamespace(userId, namespace) {

  if (!userId) {
    throw new Error('User ID needed for caching');
  }

  if ('caches' in window) {
    try {
      // remove leading and trailing slashes
      const cleanNamespace = namespace.split('/').filter(v => v).join('/');

      // return cacheAPI or a mock cacheAPI
      return namespace
        // this operation may be insecure in Firefox private mode
        ? await caches.open(`movus/user/${userId}/${cleanNamespace}`)
        : null;
    }
    catch(e) {
      log.error(new Error(`Cannot read cache: ${e && e.message}`), e);
    }
  }
};

/*
 * Drop all caches under a namespace:
 * eg dropCacheNamespace('movus/user/1/device/1')
 * or dropCacheNamespace('movus')
 */
async function dropCacheNamespace(partialNamespace) {
  if ('caches' in window) {
    try {
      // this operation may be insecure in Firefox private mode
      const cacheKeys = await caches.keys();
      const matchingCacheKeys = cacheKeys.filter(cacheKey => {
        return cacheKey.startsWith(partialNamespace);
      });
      return Promise.all(matchingCacheKeys.map(cacheKey => {
        return caches.delete(cacheKey);
      }));
    }
    catch(e) {
      log.error(new Error(`Cannot remove cache: ${e && e.message}`), e);
    }
  }
};

/*
 * Drop all caches under a user namespace:
 * eg dropUserCacheNamespace(1, 'device/1')
 */
export async function dropUserCacheNamespace(userId, namespace) {

  if (!userId) {
    throw new Error('User ID needed for caching');
  }

  return namespace
    ? dropCacheNamespace(`movus/user/${userId}/${namespace}`)
    : dropCacheNamespace(`movus/user/${userId}`);
};

/*
 * Drop entire movus cache
 */
export async function dropCache() {
  return dropCacheNamespace('movus');
};

/*
 * Simple universal unique* IDs
 * not really unique, but should be unique within a large timeframe
 */
let count = 0;
function getUniqueID() {
  // allow count to wrap around, even though its probably never practically possible
  count = count < Number.MAX_SAFE_INTEGER ? count + 1 : 1;
  return count;
}

/*
 * define API abort type. should probably be kept elsewhere
 * but it's one of a kind for now
 */
export const API_ABORT = 'api/ABORT';

/*
 * Simple Cancel controller class
 * add instances into action creators to allow cancellation context to be shared
 * between the action creator caller scope and inside the action creator function scope
 */
export class ApiRequestCanceller {

  constructor() {
    this.id = getUniqueID();
    // allow all calls to cancel() to reference this context
    this.cancel = this.cancel.bind(this);
  }

  cancelled = false;

  cancel() {
    // flag this request as cancelled in this context
    this.cancelled = true;
    // and also generate a redux action to cancel any API requests currently in progress
    return {
      type: API_ABORT,
      abortControllerId: this.id,
    };
  }

}

export const MOVUS_BADGE_COLOURS = {
  'red': {background: "#EE220C", color: "#fff"},
  'yellow': {background: "#FAD532", color: '#333'},
  'orange': {background: "#FF9301", color: '#333'},
  'blue': {background: "#097BFF", color: "#fff"},
  'black': {background: "#333333", color: "#fff"},
  'grey': {background: "#A9A9A9", color: '#333'},
  'gray': {background: "#A9A9A9", color: '#333'},
  'purple': {background: "#CB297B", color: "#fff"},
  'brown': {background: "#AB7942", color: '#fff'},
  'green': {background: "#1DB101", color: "#fff"},
  'white': {background: "#FFFFFF", color: '#333'},
  'pink': {background: "#EF5FA7", color: "#fff"},
};

export const isValidMacAddress = (str) => {
  const macPattern = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
  return macPattern.test(str);
};

export const getStartOfDayTimestamp = (date) => {
  const d = new Date(date);
  d.setUTCHours(0, 0, 0, 0);
  return d.getTime();
};

export const getEndOfDayTimestamp = (date) => {
  const d = new Date(date);
  d.setUTCHours(23, 59, 59, 999);
  return d.getTime();
};

export const getDaysInMonth = (date) => {
  const d = new Date(date);
  const lastDayOfMonth = new Date(d.getFullYear(), d.getMonth() + 1, 0);
  return lastDayOfMonth.getDate();
};

// Utility functions, constants used by charts components.
export const oneSecond = 1000;
export const oneMinute = 60 * oneSecond;
export const oneHour = 60 * oneMinute;
export const oneDay = 24 * oneHour;
export const oneWeek = 7 * oneDay;

export const dayLabels = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const hourLabels = [
  '12am',
  '1am',
  '2am',
  '3am',
  '4am',
  '5am',
  '6am',
  '7am',
  '8am',
  '9am',
  '10am',
  '11am',
  '12pm',
  '1pm',
  '2pm',
  '3pm',
  '4pm',
  '5pm',
  '6pm',
  '7pm',
  '8pm',
  '9pm',
  '10pm',
  '11pm',
];

export const getDateString = time => new Date(time).toISOString().split('T')[0];

// Validate a given value is a valid url.
export const isValidUrl = (value) => {
  const urlPattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
     '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
     '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
     '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
     '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
     '(\\#[-a-z\\d_]*)?$', // fragment locator
    'i'
  );
  return urlPattern.test(value);
};

// This function flatten the object by moving nested prop to the top level with dot annotation.
// For example: { name: 'John', work: { company: 'abc', role: 'dev' }} -> {name: 'John', work.company: 'abc', work.role: 'dev'}
export const flattenObject = (obj, parentKey = '') => {
  const flattened = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const newKey = parentKey ? `${parentKey}.${key}` : key;
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        Object.assign(flattened, flattenObject(obj[key], newKey));
      } else {
        flattened[newKey] = obj[key];
      }
    }
  }
  return flattened;
};

// Create external, internal and mailto links text (used in i18next Trans components).
// https://www.createit.com/blog/i18next-react-links-inside-translations/
export const ExternalLink = (props) => {
  return (
    <a
      href={props.to || '#'}
      target="_blank"
      rel="noreferrer"
      title={props.title || ''}
      className={props.className || ''}
    >
      {props.children}
    </a>
  );
};

export const InternalLink = (props) => {
  return (
    <Link to={props.to} >
      {props.text}
    </Link>
  );
};

export const ContactLink = (props) => {
  return (
    <a
      href={`mailto:${props.to}`}
      className={props.className}
    >
      {props.children}
    </a>
  );
};

export const showUnsavedChangesToast = () => {
  addToast({
    variant: 'warning',
    header: t('user.Remember_to_save'),
  });
};

export const generateItemCountText = (filter, count, filteredCount) => {
  // Returns Internationalised and by same library, correctly pluralised text e.g. 1 row, 2 rows.
  return filter
    ? noBreakingSpaces(t('components.common.filtered-count', {
      count: count,
      filteredCount: filteredCount,
    }))
    // In i18n library parlance, this resolves to row-with-count_one or row-with-count_other, in
    // combination with the Internationalised text. It's really quite smart.
    // The underscore and one/other are handled by the library, depending on the number.
    // _one -> 1
    // _other -> 0, 2, 3 etc.
    // "row-with-count_one": "{{count}} $t(components.common.row)",
    // "row-with-count_other": "{{count}} $t(components.common.row-other)",
    : noBreakingSpaces(t('components.common.row-with-count', {
      count: count,
    }));
};

export const generateTimeoutExceededText = (timeoutExceeded, apiTimeoutMs) => {
  return timeoutExceeded.replace('{API_TIMEOUT_MS}', apiTimeoutMs);
};

export const downtimeSavedIsHours = (downtimeSaved) => {
  // Downtime Saved hours can be a whole number between 0 and 999 inclusive.
  // The hours can also be null. 0 can be entered by users for e.g. a false alarm,
  // while null is for no hours recorded yet.
  // It's important to know if the value is null or an integer as we display a '#' when it is null.
  // We also need to explicitly check it is an int, because 0 is falsey which allowed me to create
  // 3 bugs when we decided 0 was allowed, as originally 1 was the plan, and I'm an idiot.
  // This one-liner may seem excessive, but it is used in multiple places and I like writing essays
  // and this is horrible and unreadable so let me have my nicely named function, okay?
  // The chaining is being extra careful, but really the attribute should always exist.
  return !isNaN(parseInt(downtimeSaved?.hours));
};

export const ignoreIntervalNetworkErrors = (details, isUserAction) => {
  // The endpoints that use this function get polled at an interval. Failures of that happen often
  // enough due to Network Errors. The user doesn't need to know about this. And neither do we.
  // The following Jira cards are consolidated to this single helper function:
  // https://movusoz.atlassian.net/browse/DASH-1170
  // https://movusoz.atlassian.net/browse/DASH-1174
  // https://movusoz.atlassian.net/browse/DASH-1237
  // https://movusoz.atlassian.net/browse/DASH-1239
  // It was the same code and essay length explanation comment over and over again. It is a simple
  // bit of data which means a helper function seems excessive, but the issue is that it’s copied
  // around in many places. This means it’s not obvious that the pattern exists elsewhere.
  const errorDetails = {
    class: details.class,
    message: details.message,
    extra: POLLING,
  };
  // This ternary looks backwards but is required to show the error when it is a user action.
  // undefined allows the Toaster and API Middleware to take care of it.
  const errorToast = !isUserAction ? false : undefined;
  return {
    errorDetails,
    errorToast,
  };
};
