import axios from 'axios';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import i18nextHttpBackend from 'i18next-http-backend';
import ChainedBackend from 'i18next-chained-backend';

import { getIntervalMilliSeconds } from './components/lib/utils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const { REACT_APP_CHECK_TRANSLATIONS_INTERVAL_MINUTES = 5 } = process.env;
const INTERVAL = getIntervalMilliSeconds(REACT_APP_CHECK_TRANSLATIONS_INTERVAL_MINUTES);

// DASH-1137 - Disabled caching as it causes issues. Safest to disable and not rush a solution.
// const loadFingerprints = () => {
//   const storedFingerprints = localStorage.getItem('localeFingerprints');
//   return storedFingerprints !== null ? JSON.parse(storedFingerprints) : {};
// };

export const getStoredUserLocale = () => {
  return localStorage.getItem('userLocale') || 'en'; // Refresh sets back to default otherwise
};

export const backendOption = {
  loadPath: `${API_BASE_URL}/translations?locale={{lng}}`,
  request: async (options, url, payload, callback) => {
    const loadPath = new URL(url);
    const origin = loadPath.origin;
    const pathname = loadPath.pathname;
    const queryParams = new URLSearchParams(loadPath.search);
    // DASH-1137 - Disabled caching as it causes issues. Safest to disable and not rush a solution.
    // const locale = queryParams.get('locale');
    // const storedFingerprints = loadFingerprints();
    // const fingerprint = storedFingerprints[locale];
    // if (fingerprint) queryParams.append('fingerprint', fingerprint);
    const buildUrl = new URL(origin);
    buildUrl.pathname = pathname;
    buildUrl.search = queryParams.toString();
    const requestedUrl = buildUrl.toString();

    axios.get(requestedUrl).then((result) => {
      // DASH-1137 - Disabled caching as it causes issues. Safest to disable and not rush a solution.
      // const returnedFingerprint = result.data.fingerprint;
      // if (returnedFingerprint !== undefined) {
      //   const reloadedFingerprints = loadFingerprints();
      //   if (returnedFingerprint !== reloadedFingerprints[locale]) {
      //     const fingerprints = {
      //       ...reloadedFingerprints,
      //       [locale]: returnedFingerprint,
      //     };
      //     localStorage.setItem('localeFingerprints', JSON.stringify(fingerprints));
      //     i18n.reloadResources();
      //   }
      // }
      callback(null, {
        data: result.data.translations,
        status: 200,
      });
    }).catch(() => {
      callback(null, {
        status: 500,
      });
    });
  },
  // This gets the new translations from the API, but it does not reload them. The reload happens
  // conditionally above (i18next.reloadResources()). Navigating to a new route uses them. A hard
  // refresh is pretty ugly and aggressive. They can be out of date for a short while. Sue me.
  reloadInterval: INTERVAL,
};

export const i18nInitOptions = {
  partialBundledLanguages: true,
  backend: {
    backends: [
      i18nextHttpBackend,
    ],
    backendOptions: [
      backendOption,
    ],
  },
  debug: true, // Once this settles down, disable in production
  lng: getStoredUserLocale(), // Before any other config is loaded, init starts with this language
  fallbackLng: ['en', 'es', getStoredUserLocale()],  // These get refreshed every reloadInterval
  interpolation: {
    escapeValue: false,
  },
  translationStats: {
    sourceLng: 'en-AU',
    targetLngs: ['es-MX']
  }
};

// Don't use i18next-browser-languagedetector because I don't trust crap from people's browsers.
i18n
  .use(initReactI18next)
  .use(ChainedBackend)
  .init(i18nInitOptions);

export function getDefaultI18nextInstance() {
  i18n
    .use(initReactI18next)
    .use(ChainedBackend)
    .init(i18nInitOptions);
  return i18n;
}

export function cloner(original, newI18nInitOptions) {
  original.cloneInstance({
    newI18nInitOptions
  });
}

export default i18n;
