export const REQUEST_PASSWORD_RESET_REQUEST = 'user/REQUEST_PASSWORD_RESET_REQUEST';
export const RECEIVE_PASSWORD_RESET_REQUEST = 'user/RECEIVE_PASSWORD_RESET_REQUEST';
export const PASSWORD_RESET_REQUEST_FAILURE = 'user/PASSWORD_RESET_REQUEST_FAILURE';

export const REQUEST_PASSWORD_RESET_CONFIRM = 'user/REQUEST_PASSWORD_RESET_CONFIRM';
export const RECEIVE_PASSWORD_RESET_CONFIRM = 'user/RECEIVE_PASSWORD_RESET_CONFIRM';
export const PASSWORD_RESET_CONFIRM_FAILURE = 'user/PASSWORD_RESET_CONFIRM_FAILURE';

export const REQUEST_LOGIN = 'user/REQUEST_LOGIN';
export const RECEIVE_LOGIN = 'user/RECEIVE_LOGIN';
export const LOGIN_FAILURE = 'user/LOGIN_FAILURE';

export const REQUEST_VALIDATE_OTP = 'user/REQUEST_VALIDATE_OTP';
export const RECEIVE_VALIDATE_OTP = 'user/RECEIVE_VALIDATE_OTP';
export const VALIDATE_OTP_FAILURE = 'user/VALIDATE_OTP_FAILURE';

export const REQUEST_RESEND_OTP = 'user/REQUEST_RESEND_OTP';
export const RECEIVE_RESEND_OTP = 'user/RECEIVE_RESEND_OTP';
export const RESEND_OTP_FAILURE = 'user/RESEND_OTP_FAILURE';

export const RESET_MFA_REQUIRED = 'user/RESET_MFA_REQUIRED';

export const REQUEST_GET_CONFIRM_OTP = 'user/REQUEST_GET_CONFIRM_OTP';
export const RECEIVE_GET_CONFIRM_OTP = 'user/RECEIVE_GET_CONFIRM_OTP';
export const GET_CONFIRM_OTP_FAILURE = 'user/GET_CONFIRM_OTP_FAILURE';

export const REQUEST_SEND_CONFIRM_OTP = 'user/REQUEST_SEND_CONFIRM_OTP';
export const RECEIVE_SEND_CONFIRM_OTP = 'user/RECEIVE_SEND_CONFIRM_OTP';
export const SEND_CONFIRM_OTP_FAILURE = 'user/SEND_CONFIRM_OTP_FAILURE';

export const REQUEST_USER = 'user/REQUEST_USER';
export const RECEIVE_USER = 'user/RECEIVE_USER';
export const USER_FAILURE = 'user/USER_FAILURE';

export const REQUEST_USER_PICTURE_UPLOAD_S3_FILE_URL = 'user/REQUEST_USER_PICTURE_UPLOAD_S3_FILE_URL';
export const RECEIVE_USER_PICTURE_UPLOAD_S3_FILE_URL = 'user/RECEIVE_USER_PICTURE_UPLOAD_S3_FILE_URL';
export const USER_PICTURE_UPLOAD_S3_FILE_URL_FAILURE = 'user/USER_PICTURE_UPLOAD_S3_FILE_URL_FAILURE';

export const REQUEST_USER_PICTURE = 'user/REQUEST_USER_PICTURE';
export const RECEIVE_USER_PICTURE = 'user/RECEIVE_USER_PICTURE';
export const USER_PICTURE_FAILURE = 'user/USER_PICTURE_FAILURE';

export const REQUEST_USER_TAGS = 'user/REQUEST_USER_TAGS';
export const RECEIVE_USER_TAGS = 'user/RECEIVE_USER_TAGS';
export const USER_TAGS_FAILURE = 'user/USER_TAGS_FAILURE';

export const RECEIVE_USER_INFO = 'user/RECEIVE_USER_INFO';

export const REQUEST_USER_DEVICES = 'user/REQUEST_USER_DEVICES';
export const RECEIVE_USER_DEVICES = 'user/RECEIVE_USER_DEVICES';
export const USER_DEVICES_FAILURE = 'user/USER_DEVICES_FAILURE';

export const REQUEST_ARCHIVED_USER_DEVICES = 'user/REQUEST_ARCHIVED_USER_DEVICES';
export const RECEIVE_ARCHIVED_USER_DEVICES = 'user/RECEIVE_ARCHIVED_USER_DEVICES';
export const ARCHIVED_USER_DEVICES_FAILURE = 'user/ARCHIVED_USER_DEVICES_FAILURE';

export const SET_LOCAL_PREFERENCE = 'user/SET_LOCAL_PREFERENCE';
export const SET_USER_LOCAL_PREFERENCE = 'user/SET_USER_LOCAL_PREFERENCE';
export const SET_USER_OPTIMISTIC_REMOTE_PREFERENCE = 'user/SET_USER_OPTIMISTIC_REMOTE_PREFERENCE';
export const UNSET_USER_OPTIMISTIC_REMOTE_PREFERENCE = 'user/UNSET_USER_OPTIMISTIC_REMOTE_PREFERENCE';

export const SET_APP_LOCALE = 'user/SET_APP_LOCALE';
export const SET_APP_LOCALE_STALE = 'user/SET_APP_LOCALE_STALE';

export const REQUEST_UPDATE_USER_PREFERENCES = 'user/REQUEST_UPDATE_USER_PREFERENCES';
export const RECEIVE_UPDATE_USER_PREFERENCES = 'user/RECEIVE_UPDATE_USER_PREFERENCES';
export const UPDATE_USER_PREFERENCES_FAILURE = 'user/UPDATE_USER_PREFERENCES_FAILURE';

export const REQUEST_UPDATE_USER_DEVICES = 'user/REQUEST_UPDATE_USER_DEVICES';
export const RECEIVE_UPDATE_USER_DEVICES = 'user/RECEIVE_UPDATE_USER_DEVICES';
export const UPDATE_USER_DEVICES_FAILURE = 'user/UPDATE_USER_DEVICES_FAILURE';

export const TOKEN_EXPIRED = 'user/TOKEN_EXPIRED';

export const LOGOUT = 'user/LOGOUT';

export const REQUEST_USERS = 'users/REQUEST_USERS';
export const RECEIVE_USERS = 'users/RECEIVE_USERS';
export const USERS_FAILURE = 'users/USERS_FAILURE';

export const REQUEST_CREATE_USER = 'user/REQUEST_CREATE_USER';
export const RECEIVE_CREATE_USER = 'user/RECEIVE_CREATE_USER';
export const CREATE_USER_FAILURE = 'user/CREATE_USER_FAILURE';

export const REQUEST_UPDATE_USER = 'user/REQUEST_UPDATE_USER';
export const RECEIVE_UPDATE_USER = 'user/RECEIVE_UPDATE_USER';
export const UPDATE_USER_FAILURE = 'user/UPDATE_USER_FAILURE';

export const REQUEST_USER_TYPES = 'user/REQUEST_USER_TYPES';
export const RECEIVE_USER_TYPES = 'user/RECEIVE_USER_TYPES';
export const USER_TYPES_FAILURE = 'user/USER_TYPES_FAILURE';

export const REQUEST_ARCHIVE_USER = 'user/REQUEST_ARCHIVE_USER';
export const RECEIVE_ARCHIVE_USER = 'user/RECEIVE_ARCHIVE_USER';
export const ARCHIVE_USER_FAILURE = 'user/ARCHIVE_USER_FAILURE';

export const REQUEST_USER_SUBSCRIPTIONS = 'user/REQUEST_USER_SUBSCRIPTIONS';
export const RECEIVE_USER_SUBSCRIPTIONS = 'user/RECEIVE_USER_SUBSCRIPTIONS';
export const RECEIVE_UPDATE_USER_SUBSCRIPTIONS = 'user/RECEIVE_UPDATE_USER_SUBSCRIPTIONS';
export const USER_SUBSCRIPTIONS_FAILURE = 'user/USER_SUBSCRIPTIONS_FAILURE';
