
import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { Row, Col, Form, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { getLocalAnonymousPreference } from '../modules/user/selectors';
import { setLocalAnonymousPreference } from '../modules/user/actions';

import FormModal from './FormModal';
import { backendOption, cloner, getDefaultI18nextInstance, i18nInitOptions } from '../i18n';

const apiDefault = process.env['REACT_APP_API_BASE_URL'];
const apis = {
  release: process.env['REACT_APP_SWITCHABLE_API_RELEASE'],
  dev: process.env['REACT_APP_SWITCHABLE_API_DEV'],
  staging: process.env['REACT_APP_SWITCHABLE_API_STAGING'],
  common: process.env['REACT_APP_SWITCHABLE_API_COMMON'],
  prod: process.env['REACT_APP_SWITCHABLE_API_PROD'],
  custom: apiDefault,
};

function useApi() {
  return useSelector(state => {
    return getLocalAnonymousPreference(state, 'api_url') || apiDefault;
  });
}

// do a reverse lookup to find the 'current mode' from a API URL
function useMode() {
  const api = useApi();
  // find matching mode key
  const mode = useMemo(() => {
    const index = Object.values(apis).findIndex(selectedAPI => selectedAPI === api);
    return Object.keys(apis)[index];
  }, [api]);
  return mode || 'custom';
}

export function useDevApiModal() {

  const dispatch = useDispatch();

  const formRef = useRef(null);

  const mode = useMode();
  const api = useApi();
  const [selectedMode, setSelectedMode] = useState(mode);
  const [selectedAPI, setSelectedApi] = useState(api);

  // save custom URL for later
  useEffect(() => {
    if (selectedMode === 'custom') {
      apis['custom'] = selectedAPI;
    }
  }, [apis, selectedMode, selectedAPI]);

  const handleSubmit = useCallback(async () => {
    // set API
    if (selectedMode !== 'custom' || `${selectedAPI || ''}`.trim()) {
      dispatch(setLocalAnonymousPreference('api_url', selectedAPI));
    }
    const newI18nInitOptions = {...i18nInitOptions};
    const newBackendOption = {...backendOption};
    newBackendOption['loadPath'] = `${selectedAPI}/translations?locale={{lng}}`;
    newI18nInitOptions.backend.backendOptions = [newBackendOption];
    // This will give a warning about calling init twice. This is just a dev mode and I can't find
    // any other way right now. Hmm.
    const original = getDefaultI18nextInstance({});
    cloner(original, newI18nInitOptions);

  }, [dispatch, setLocalAnonymousPreference, selectedMode, selectedAPI]);

  return {
    formRef,
    apis,
    api,
    selectedMode,
    setSelectedMode,
    selectedAPI,
    setSelectedApi,
    handleSubmit,
  };
}

function DevApiModal({ children, ...props }) {

  const {
    formRef,
    apis,
    api,
    selectedMode,
    setSelectedMode,
    selectedAPI,
    setSelectedApi,
    handleSubmit,
  } = useDevApiModal();

  return (
    <FormModal
      // set defaults
      header="Set Platform API"
      confirmText="Set API"
      // add given props
      {...props}
      // override given props
      size="lg"
      valid={selectedAPI && selectedAPI !== api}
      form={(
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Form.Group as={Row} controlId="group_type">
            <Form.Label column sm={5}>
              API
            </Form.Label>
            <Col sm={7}>
              <ToggleButtonGroup
                type="radio"
                name="group_type"
                value={selectedMode}
                onChange={selectedMode => {
                  setSelectedMode(selectedMode);
                  setSelectedApi(apis[selectedMode]);
                }}
              >
                {Object.keys(apis).map(key => (
                  <ToggleButton
                    key={key}
                    variant={selectedMode === key ? 'primary' : 'light'}
                    value={key}
                  >
                    {key}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Col>
          </Form.Group>
          <Form.Control
            type="text"
            name="selectedAPI"
            value={selectedAPI}
            disabled={selectedMode !== 'custom'}
            onChange={e => setSelectedApi(e?.target?.value ?? '')}
          />
        </Form>
      )}
    >
      {children}
    </FormModal>
  );
}

const badgeStyle = { cursor: 'pointer' };

export default function DevApiBadge() {

  const api = useApi();
  const mode = useMode();

  // The badge and modal will only display if the environment variable
  // process.env.REACT_APP_SWITCHABLE_API is true

  if (process.env.REACT_APP_SWITCHABLE_API !== 'true') {
    return null;
  }

  return (
    <DevApiModal>
      <span style={badgeStyle}>
        <OverlayTrigger placement="top" overlay={(<Tooltip>{api}</Tooltip>)}>
          <Badge variant={mode === 'prod' ? 'danger' : 'warning'}>
            API: {mode}
          </Badge>
        </OverlayTrigger>
      </span>
    </DevApiModal>
  );
}
