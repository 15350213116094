import React, { Fragment, useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Dropdown } from 'react-bootstrap';
import { t } from 'i18next';

/* notes:
 * This abstraction and the underlying library in particular are terrible.
 * react-bootstrap-table-next should never have been picked for this project.
 *
 * The main issues around working with react-bootstrap-table-next are:
 * - and over-reliance of an 'id' field (keyField), many issues come back to this
 *   - eg. the "selected" functionality requires selected rows to be defined by an array of ids
 * - the sortFunc arguments are *only* from table data
 *   - https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/column-props.html#columnsortfunc-function
 *   - if you want to sort by something that is not in the data
 *     (eg. does the row match the current search text?)
 *     you need to append that result into the table data to be able to sort by it
 *   - you cannot update the sortFunc dynamically as the library only uses the initial function
 *     - the library could handle this but doesn't
 *     - very frustrating: a lot of the backflips done manipulating the table data could be resolved by this
 */

// Imports for react-bootstrap-table2
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import "./table.scss";
import { setLocalUserPreference } from '../modules/user/actions.js';
import { getUser, getUserPreference } from '../modules/user/selectors.js';
import { useTranslation } from 'react-i18next';

import { generateItemCountText } from '../lib/utils';

// determine from props whether we should use our custom PaginatedTable component
function isPaginated({ pagination, paginationOptions }) {
  // allow pagination === true to use our default pagination table.
  // but pagination can still be given a paginationFactory if desired
  // that will not use our custom PaginatedTable component
  return pagination === true || !!paginationOptions;
}

// The following three functions are valid React components so you would expect to be able to use
// the useTranslation hook. The problem is that these are injected as defaults to BaseTable and as
// such aren't instantiated which causes an "Invalid hook call". It's possible to instantiate them
// e.g.: noDataIndication = DefaultNoDataIndication(), but that seems silly. Passing functions is
// legit, but passing and instantiating things is an unexpected side effect.
function DefaultLoadingDataIndication() {
  return (
    <div>{t('common.loading')}...</div>
  );
}

function DefaultNoDataIndication() {
  return (
    <div>{t('common.no-data')}</div>
  );
}

function DefaultNoFilteredDataIndication({ searchProps: { searchText }={}, hasFilter = false }) {
  if(!!hasFilter) return <div>{t('common.no-search-results-filter')}</div>;
  return `${searchText || ''}`.trim().length ? (
    <div>{t('common.no-search-results-text', {
      searchText: searchText,
    })}</div>
  ) : (
    <div>{t('common.no-data')}</div>
  );
}

function BaseTable(props) {
  const {
    // things for our custom table
    loading,
    renderHeader,
    renderFooter,
    pagination,
    refreshHandler,
    loadingDataIndication = DefaultLoadingDataIndication,
    noDataIndication = DefaultNoDataIndication,
    noFilteredDataIndication = DefaultNoFilteredDataIndication,
    // things for around the table
    searchProps,
    csvProps,
    columnToggleProps,
    paginationProps,
    setPaginationSizePerPage,
    customProps,
    hasFilter,
    // things for react-bootstrap-table2
    ...tableProps
  } = props;

  const {
    data = [],
    hasData = !!data.length,
  } = tableProps;

  const totalCount = data.length;
  const [dataSizeProps, setDataSizeProps] = useState({
    filteredCount: totalCount,
    totalCount,
  });
  useEffect(() => {
    if(customProps?.initialDataSize >= 0) {
      setDataSizeProps({filteredCount: customProps.initialDataSize});
    }
  }, [customProps?.initialDataSize]);

  const auxiliaryProps = {
    // defined by react-bootstrap-table2
    searchProps,
    csvProps,
    columnToggleProps,
    paginationProps: {...paginationProps, dataSize: dataSizeProps.filteredCount},
    dataSizeProps,
    // custom state handlers
    setPaginationSizePerPage,
    customProps,
  };

  // if a refreshHandler is specified then set up a timer to execute it
  useEffect(() => {
    if (refreshHandler) {
      // set a recurring interval to execute the refreshHandler
      const interval = setInterval(refreshHandler, 1000*60*5); // tick every 5 minutes
      // return a cleanup handler for when the component will unmount
      return () => clearInterval(interval);
    }
  }, []);

  const noFilteredDataIndicationWithContext = useCallback(() => {
    return noFilteredDataIndication({ searchProps, hasFilter });
  }, [searchProps, hasFilter]);

  return (
    <Fragment>
      {typeof renderHeader === 'function' && renderHeader(auxiliaryProps)}
      <BootstrapTable
        // set style defaults, these can be overridden by given props
        bordered={false}
        striped
        hover
        responsive
        noDataIndication={noDataIndication}
        onDataSizeChange={({ dataSize }) => {
          setDataSizeProps({
            filteredCount: dataSize,
            totalCount,
          });
        }}
        // given props
        {...tableProps}
        // ensure data is an empty array if given undefined
        data={data}
        // ensure pagination in an object
        pagination={typeof pagination === 'object' ? pagination : undefined}
        // override props
        bootstrap4
        // override display when there is data, but it is filtered to no results
        {...hasData ? {
          noDataIndication: noFilteredDataIndicationWithContext,
        } : {}}
        // override display when loading
        {...loading ? {
          noDataIndication: loadingDataIndication,
        } : {}}
      />
      {typeof renderFooter === 'function' && renderFooter(auxiliaryProps)}
    </Fragment>
  );
}

// accepts "paginationOptions" as an object of options
// and a custom renderFooter
function PaginatedTable(props) {

  const dispatch = useDispatch();
  const {
    pagination: ignore, // ignore flag so that it doesn't get passed down
    paginationOptions = {},
    renderFooter = renderPaginationFooter, // default to render inside a Col
    ...tableProps
  } = props;
  const user = useSelector(getUser);

  const localSizePerPage = useSelector(state => getUserPreference(state, 'sizePerPage')) || 25;
  const [sizePerPage, setPaginationSizePerPage] = useState(paginationOptions.sizePerPage || localSizePerPage);

  const handleSetPaginationSizePerPage = (newSizePerPage) => {
    if(user) {
      dispatch(setLocalUserPreference(user, 'sizePerPage', newSizePerPage));
    }
    setPaginationSizePerPage(newSizePerPage);
  };

  // only compute the paginationProp when its dependencies change
  const paginationProp = useMemo(() => paginationFactory({
    // set some sane default options
    custom: true,
    hidePageListOnlyOnePage: true,
    // add the given options
    ...paginationOptions,
    // todo: To avoid resetting to page 1 on every search change.
    // This is currently done because at this level of context we do not know the dataSize.
    // If you are viewing page 2 of data, then perform a search with 0 results
    // then React will throw an error because currently the table handles filtering internally,
    // and re-renders before firing the onDataSizeChange handler.
    // It is recommended to use switch to a remote table and handle all filtering
    // actions with our own handlers on the BaseTable component.
    // https://github.com/react-bootstrap-table/react-bootstrap-table2/issues/432
    // Declaring the pagination as custom=false, also allows the table to switch pages correctly
    // as this is done further down where it has the context of what the dataSize is.
    page: 1,
    // add stateful information
    sizePerPage,
  }), [
    // recompute when new sizePerPage state information is available
    sizePerPage,
    // react hooks may show a warning in development if the array size has changed
    // but regardless: it will compare arrays of different sizes correctly
    // and will do so in production without warning
    // see: https://github.com/facebook/react/blob/03944bfb0bdacfe35b2a1722426ff744ae47d018/packages/react-reconciler/src/ReactFiberHooks.js#L336-L358
    // allow shallow comparison of paginationOptions keys and values
    ...Object.keys(paginationOptions),
    ...Object.values(paginationOptions),
  ]);

  // these props are from the pagination provider,
  // and include props.paginationTableProps and props.paginationProps
  const renderTable = useCallback(({ paginationTableProps, ...otherPaginationProps }) => {
    return (
      <BaseTable
        // apply given props
        {...tableProps}
        // apply pagination props for the table
        {...paginationTableProps}
        // pass through pagination props for other table elements
        {...otherPaginationProps}
        // give a default footer if not specified
        renderFooter={renderFooter}
        // allow ability to set the pagination size
        setPaginationSizePerPage={handleSetPaginationSizePerPage}
      />
    );
  }, [
    renderFooter,
    setPaginationSizePerPage,
    // recalculate if tableProp keys change
    ...Object.keys(tableProps),
    // recalculate if table prop values change
    ...Object.values(tableProps),
  ]);

  return (
    <PaginationProvider pagination={paginationProp}>
      {renderTable}
    </PaginationProvider>
  );
}

// accepts "search" as a boolean or object of options
function ToolkitTable(props) {
  const { data=[], columns, keyField='id', search } = props;
  // these props are from the toolkit provider,
  // and include baseProps, searchProps, csvProps, columnToggleProps
  const renderTable = useCallback(({ baseProps, ...otherToolkitProps }) => {
    const SubTable = isPaginated(props) ? PaginatedTable : BaseTable;
    return (
      <SubTable
        // apply given props
        {...props}
        // apply toolkit props for the table
        {...baseProps}
        // pass through searchProps and other props for other table elements
        {...otherToolkitProps}
      />
    );
  }, [
    // change if shallow compare props has changed
    ...Object.keys(props),
    ...Object.values(props),
  ]);

  return (
    <ToolkitProvider
      keyField={keyField}
      data={data}
      columns={columns}
      // declare the table to be searchable or not, can be object or boolean
      search={typeof search === 'object' ? search : !!search}
    >
      {renderTable}
    </ToolkitProvider>
  );
}

// accepts "search" as a boolean or object of options
// accepts "pagination" as a boolean or results of a paginationFactory
// accepts "paginationOptions" as an object of options, that will combine with our default options
export default function Table({ search=true, pagination=false, ...props }) {
  // default all tables to searchable tables
  // default all tables to non-paginated tables
  return (
    <ToolkitTable search={search} pagination={pagination} {...props} />
  );
}

const { SearchBar } = Search;

// replace spaces with non-breaking spaces
export function noBreakingSpaces(text) {
  return `${text}`.replace(/\s/g, '\u00A0');
}

export const PaginationBar = ({ paginationProps }) => {
  const {
    hidePageListOnlyOnePage,
    dataSize,
    sizePerPage,
  } = paginationProps;

  // hide if there is only one page
  if (hidePageListOnlyOnePage && dataSize <= sizePerPage) {
    return null;
  }
  // else return the component
  return (
    <div className="react-bootstrap-table2-pagination">
      <PaginationListStandalone { ...paginationProps }/>
    </div>
  );
};

export const PaginationSize = ({ paginationProps, setPaginationSizePerPage: set }) => {
  // set max to the JS limit (Infinity causes an error :/)
  const { t } = useTranslation();
  const max = Number.MAX_VALUE;
  const { sizePerPage } = paginationProps;
  const options = [5, 10, 25, 50, max];
  return (
    <Dropdown>
      <Dropdown.Toggle>
        {t('components.table.rows-per-page')}: {sizePerPage === max ? t('All') : sizePerPage}
      </Dropdown.Toggle>
      <Dropdown.Menu align="right">
        {options.map(value => (
          <Dropdown.Item
            key={value}
            onClick={() => set(value)}
            active={sizePerPage === value}
          >
            {value === max ? t('All') : value}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const renderPaginationFooter = ({ paginationProps, setPaginationSizePerPage }) => (
  <Row className="mt-3">
    <Col xs="auto">
      <PaginationSize
        paginationProps={paginationProps}
        setPaginationSizePerPage={setPaginationSizePerPage}
      />
    </Col>
    <Col xs="auto" className="react-bootstrap-table2-pagination-align-right">
      <PaginationBar
        paginationProps={paginationProps}
      />
    </Col>
  </Row>
);

export const DefaultSearchBar = ({ searchProps, onSearch }) => {
  const { t } = useTranslation();
  return (
    <SearchBar
      className="align-middle d-inline-block react-bootstrap-table2-search-header"
      { ...searchProps }
      placeholder={t('common.search')}
      onSearch={(text) => {
        searchProps.onSearch(text);
        if(typeof onSearch === 'function') onSearch(text);
      }}
    />
  );
};

export function itemCount({ dataSizeProps={} }) {
  // /equipment/list
  const {
    totalCount = 0,
    filteredCount = 0,
  } = dataSizeProps;

  const filter = totalCount !== filteredCount;
  return generateItemCountText(filter, totalCount, filteredCount);
}
