import React, { useEffect, useState } from 'react';
import { Button, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Private from '../../../components/Private';
import { downtimeSavedForm } from '../../../constants';
import { downtimeSavedIsHours } from '../../../lib/utils';

import useAlarmDowntimeSaved from '../hooks/useAlarmDowntimeSaved';
import './alarmDowntimeSaved.scss';

function AlarmDowntimeSavedForm({ alarmId }) {
  const { t } = useTranslation();
  const [editing, setEditing] = useState(false);

  const {
    loading,
    alarmDowntimeSaved,
    fetchDowntimeSaved,
    updateDowntimeSaved,
  } = useAlarmDowntimeSaved(alarmId);

  const defaultValues = {
    downtime_saved: alarmDowntimeSaved?.hours,
  };
  const {
    register,
    handleSubmit,
    setValue,
    reset,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: defaultValues,
  });

  useEffect(() => {
    if (downtimeSavedIsHours(alarmDowntimeSaved)) {
      setValue('downtime_saved', parseInt(alarmDowntimeSaved.hours));
    }
  }, [alarmDowntimeSaved]);

  useEffect(() => {
    if (alarmId) {
      fetchDowntimeSaved(alarmId);
    }
  }, [fetchDowntimeSaved, alarmId]);

  const onCancel = () => {
    reset(
      defaultValues,
      {
        keepDefaultValues: true,
      });
    setEditing(false);
  };

  const onSubmit = (data) => {
    updateDowntimeSaved(data);
    setEditing(false);
  };

  if (!alarmId) return null;

  const formStyle = {
    width: '2.75em',
    border: '1px solid #ccc',
    background: 'transparent',
    fontSize: '3em',
    textAlign: 'right',
    marginRight: '0',
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group
          as={Row}
          controlId={"downtimeSaved"}
          className="d-flex align-items-center"
        >
          <div style={{ marginRight: '5px' }}>
            <Form.Control
              type="number"
              inputMode="numeric"
              step="1"
              min={downtimeSavedForm.minimumHours}
              max={downtimeSavedForm.maximumHours}
              placeholder={downtimeSavedForm.placeholder}
              {...register('downtime_saved', {
                required: true,
                valueAsNumber: true,
              })}
              style={editing ? formStyle : {
                ...formStyle,
                border: '1px solid transparent',
              }}
              className="no-spinners"
              disabled={loading || !editing}
            />
          </div>
          <div className={!editing ? "vertical-line" : "vertical-line-transparent"}></div>
          <div style={{ marginLeft: '5px', width: '8em' }}>
            {/*
              The hours passed as "count" are not displayed as the string doesn't have that
              variable. The hours are used purely to make the string plural or singular. Note that
              the variable must be called "count" for the i18n library to recognise it.
            */}
            <div>
              {t('components.alarms.alarm-downtime-saved.hours-of-downtime-saved', {
                count: alarmDowntimeSaved?.hours,
              })}
            </div>
            <div>
              {/*
                Hiding and showing instead of conditional rendering is because when conditionally
                bringing the Save button in, it auto submits. I can't figure out why. ¯\_(ツ)_/¯
              */}
              <>
                <Private minUserType="Admin">
                  <Button
                    size="sm"
                    disabled={loading}
                    onClick={() => setEditing(true)}
                    style={!editing ? {} : { display: 'none' }}
                  >
                    {t('Edit')}
                  </Button>
                </Private>
                <Button
                  variant="success"
                  type="submit"
                  size="sm"
                  disabled={loading}
                  style={editing ? {} : { display: 'none' }}
                >
                  {t('common.save')}
                </Button>&nbsp;
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => onCancel()}
                  style={editing ? {} : { display: 'none' }}
                >
                  {t('cancel')}
                </Button>
              </>
            </div>
          </div>
        </Form.Group>
      </Form>
    </>
  );
}

export default AlarmDowntimeSavedForm;
