import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import withNavigationGroupProps from '../components/withNavigationGroupProps';

import Title from '../../../components/Title';
import {
  GroupAccessTableCard,
  UserAccessTableCard,
  UserMembersTableCard,
  DeviceMembersTableCard,
} from '../components/GroupAccess';

import {
  fetchGroupMembers,
  fetchGroupAccess,
} from '../actions';

import {
  getGroupUsers,
  getGroupDevices,
  getGroupAccessUsers,
  getGroupAccessGroups,
  getGroup,
  getActiveGroupNodeInfo,
  getCurrentOrganisationHasProductCode,
} from '../selectors';

function AlertWithHeading({ variant, title, children }) {
  return (
    // make sure the HTML doesn't set the 'role' accessibility
    // as this isn't an actual alert, it's just styled as one
    <Alert variant={variant} role={undefined}>
      <Alert.Heading className="mt-1 mb-2 pb-1">
        {title}
      </Alert.Heading>
      {children}
    </Alert>
  );
}

function EditGroupAccessPage({
  groupId,
  groupName,
  isUserGroup,
  isOrganisationGroup,
  devices,
  users,
  accessUsers,
  accessGroups,
  fetchGroupMembers,
  fetchGroupAccess,
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState({});

  // load everything
  useEffect(() => {
    if (groupId) {
      const submittedAt = Date.now();
      setLoading({ submittedAt });
      Promise.all([
        fetchGroupMembers({ id: groupId }),
        fetchGroupAccess({ id: groupId }),
      ]).then(() => {
        setLoading(state => {
          return state.submittedAt === submittedAt
            ? { succeededAt: new Date() }
            : state;
        });
      }).catch(error => {
        setLoading(state => {
          return state.submittedAt === submittedAt
            ? { error: `${error && error.message}` }
            : state;
        });
      });
    }
  }, [groupId]);

  const hasUserGroupsFeature = useSelector(state => getCurrentOrganisationHasProductCode(state, 'groups_user'));
  const fallbackGroupName = t('Group');
  return (
    <Container className="access-cards" fluid>
      <Title
        title={t('components.organisations.edit-group-access.access-title', {
          groupName: groupName ? groupName : fallbackGroupName
        })}
        loading={loading.submittedAt}
        lastFetch={loading.succeededAt}
        error={loading.error}
      />
      <Row>
        <Col className="flow-col">
          <AlertWithHeading
            variant="info"
            title={isUserGroup ?
              t('components.organisations.edit-group-access.group-users') :
              t('components.organisations.edit-group-access.group-equipment')
            }
          >
            <Row>
              {!isUserGroup && <Col xl={12}>
                <DeviceMembersTableCard
                  devices={devices}
                  loading={loading.submittedAt}
                />
              </Col>}
              {hasUserGroupsFeature && isUserGroup && <Col xl={12}>
                <UserMembersTableCard
                  users={users}
                  loading={loading.submittedAt}
                />
              </Col>}
            </Row>
          </AlertWithHeading>
        </Col>
        {hasUserGroupsFeature && !isUserGroup && <Col className="flow-col">
          <AlertWithHeading
            variant="warning"
            title={t('components.organisations.edit-group-access.has-access')}
          >
            <Row>
              <Col xl={12}>
                <UserAccessTableCard
                  users={accessUsers}
                  loading={loading.submittedAt}
                  isOrganisationGroup={isOrganisationGroup}
                />
              </Col>
              <Col xl={12}>
                <GroupAccessTableCard
                  groups={accessGroups}
                  loading={loading.submittedAt}
                />
              </Col>
            </Row>
          </AlertWithHeading>
        </Col>}
      </Row>
    </Container>
  );
}

const mapStateToProps = (state, { groupId }) => {
  const {
    isUserGroup,
    isOrganisationGroup,
  } = getActiveGroupNodeInfo(state);
  return {
    isUserGroup,
    isOrganisationGroup,
    groupName: (getGroup(state, groupId) || {}).group_name,
    users: getGroupUsers(state, groupId),
    devices: getGroupDevices(state, groupId),
    accessUsers: getGroupAccessUsers(state, groupId),
    accessGroups: getGroupAccessGroups(state, groupId),
  };
};

const mapDispatchToProps = {
  fetchGroupMembers,
  fetchGroupAccess,
};

export default withNavigationGroupProps(
  connect(mapStateToProps, mapDispatchToProps)(EditGroupAccessPage)
);
