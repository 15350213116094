import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import {
  fetchGroup,
  fetchGroupAccess,
  addGroupAccessGroups,
  deleteGroupAccessGroups,
} from '../actions';

import TableListEdit, {
  included,
  createSelectAllHeaderFormatter,
  createSelectAllFormatter,
  createCheckboxFormatter,
  sortByActiveThenString,
} from '../../../components/TableListEdit';

import { addToast } from '../../../components/Toaster';
import {
  groupName,
} from '../columns';
import useLocalisedColumns from '../../../hooks/useLocalisedColumns';

import { hasAdminRightsInActiveGroup } from '../../user/selectors';
import {
  getGroupAccess,
  isTopLevelGroupActive,
  isStandardOrganisation,
  getNearestGroupOrganisationAncestor,
  getNearestOrganisationAncestorId,
  getActiveGroupNodeInfo,
} from '../selectors';

import { capitaliseFirstChar } from '../../../lib/utils';

const defaultSorted = [{
  dataField: groupName.dataField,
  order: 'asc'
}];

const userColumns = [{
  ...groupName,
  sortFunc: sortByActiveThenString,
}];

const linearPermissionList = [
  'read',
  'write',
  'admin',
];

const permissionList = [
  ...linearPermissionList,
  'login',
];

export const checkboxColumns = [
  ...linearPermissionList.map((permission, index, permissions) => ({
    ...included,
    dataField: `_meta_.${permission}`,
    text: `${capitaliseFirstChar(permission)} access`,
    headerFormatter: createSelectAllHeaderFormatter(permission, {
      alsoSet: permissions.slice(0, index),
      alsoUnset: permissions.slice(index),
    }),
    formatter: createCheckboxFormatter(permission, {
      alsoSet: permissions.slice(0, index),
      alsoUnset: permissions.slice(index),
    }),
  })),
];

const checkAllColumn = {
  dataField: `_meta_._all_`,
  text: `All access`,
  headerFormatter: createSelectAllHeaderFormatter('_all_', {
    alsoSet: permissionList,
  }),
  formatter: createSelectAllFormatter('_all_', {
    alsoSet: permissionList,
  }),
};

const columns = [
  ...userColumns,
  checkAllColumn,
  ...checkboxColumns,
];


const searchKeys = [
  groupName.dataField,
];

const returnTrue = () => true;
const returnAllFieldsFalse = () => permissionList.reduce((acc, key) => {
  return {
    ...acc,
    [key]: false,
  };
}, {});

// parse form data before receiving it in onSubmit
function parse(group) {
  // include full group object only if any of the permissions are true
  if (group && group._meta_ && permissionList.find(permission => {
    return group._meta_[permission] === true;
  })) {
    return group;
  }
}

function EditGroupAccessGroups({
  groupId,
  isUserGroup,
  isOrganisationGroup,
  topLevelGroupActive,
  userCanViewGroups,
  userCanEditGroups,
  organisationIsStandard,
  accessGroups,
  availableGroups,
  fetchGroup,
  fetchGroupAccess,
  addGroupAccessGroups,
  deleteGroupAccessGroups,
}) {
  const { t } = useTranslation();
  // unroll nested groups into a flat list
  const filteredGroups = useMemo(() => {
    function getMembers(group) {
      return [
        // add this group if it is a user group
        group.type === 'user' && group,
        // add nested groups
        ...group.members
          ? group.members.map(getMembers).flat()
          : [],
      ];
    }
    return (availableGroups || []).map(getMembers).flat().filter(Boolean);
  }, [availableGroups]);

  const encodeMeta = useMemo(() => {
    if (isOrganisationGroup) {
      return returnAllFieldsFalse;
    }
    return user => {
      const accessGroup = (accessGroups || []).find(({ id }) => id === user.id);
      const rights = (accessGroup && accessGroup.rights && accessGroup.rights) || [];
      return permissionList.reduce((acc, key) => {
        return {
          ...acc,
          [key]: rights.includes(key),
        };
      }, {});
    };
  }, [isOrganisationGroup, accessGroups]);

  const [loadingGroups, setLoadingGroups] = useState({});
  // load organisation detail
  useEffect(() => {
    const group = { id: groupId };
    // fetch groups if needed
    // add already fetching state
    async function loadGroups() {
      const submittedAt = Date.now();
      setLoadingGroups({ submittedAt });
      try {
        await Promise.all([
          // get group details
          fetchGroup(group),
          fetchGroupAccess(group),
        ]);
        setLoadingGroups(state => {
          return state.submittedAt === submittedAt
            ? { succeededAt: new Date() }
            : state;
        });
      }
      catch (error) {
        setLoadingGroups(state => {
          return state.submittedAt === submittedAt
            ? { error: error.message || 'Error' }
            : state;
        });
      }
    }
    // execute async code
    if (group.id) {
      loadGroups();
    }
  }, [groupId, topLevelGroupActive]);

  const onSubmit = useCallback(async includedGroups => {
    const group = { id: groupId };

    function parseItems({ id, _meta_: { active, ...rights }={}}) {
      return {
        id,
        // add rights keys if they have matching meta keys
        rights: Object.entries(rights)
          .filter(([key]) => permissionList.includes(key))
          .filter(([, value]) => !!value)
          .map(([key]) => key),
      };
    }

    // get groups list from current form state
    const newGroups = (includedGroups || []).map(parseItems).filter(Boolean);
    const oldGroups = (accessGroups || []);
    const addGroups = newGroups.filter(newUser => (
      // include if this user was not known before
      (!oldGroups.find(oldUser => newUser.id === oldUser.id)) ||
      // or include if the user was known but their rights have changed
      (oldGroups.find(oldUser => {
        return (newUser.id === oldUser.id) &&
          ((newUser.rights || []).sort().join('-') !== (oldUser.rights || []).sort().join('-'));
      }))
    ));
    const delGroups = oldGroups.filter(oldUser => !newGroups.find(newUser => newUser.id === oldUser.id));

    if (addGroups.length || delGroups.length) {
      await Promise.all([
        addGroups.length && addGroupAccessGroups(group, addGroups),
        delGroups.length && deleteGroupAccessGroups(group, delGroups.map(({ id }) => id)),
      ]);
      addToast({ variant: 'success', header: t('toasts.groups-updated-successfully') });
      await fetchGroupAccess(group);
    } else {
      addToast({ variant: 'warning', header: t('toasts.no-changes-made') });
    }
  }, [
    addGroupAccessGroups,
    deleteGroupAccessGroups,
    fetchGroupAccess,
    groupId,
    accessGroups,
  ]);

  const noDataIndication = useCallback(() => {
    return t('components.organisations.edit-group-access-groups.no-permissions');
  }, []);
  const localisedColumns = useLocalisedColumns(columns);

  // if this is not a device group:
  // the user should be guided towards the appropriate access editing page
  if (isUserGroup) {
    return <Redirect to="/group/access" />;
  }

  if (!userCanViewGroups) {
    return null;
  }

  return (
    <TableListEdit
      before={organisationIsStandard ? null : (
        <Alert variant="warning">
          <p>
            {t('components.organisations.edit-group-access-groups.cannot-modify')}
          </p>
          <p className="mb-0">
            {t('components.organisations.edit-group-access-groups.sign-in')}
          </p>
        </Alert>
      )}
      title={t('components.organisations.edit-group-access-groups.title')}
      columns={localisedColumns}
      defaultSorted={defaultSorted}
      loading={loadingGroups}
      noDataIndication={noDataIndication}
      list={filteredGroups}
      encodeMeta={isOrganisationGroup ? returnAllFieldsFalse : encodeMeta}
      parse={parse}
      isItemDisabled={isOrganisationGroup ? returnTrue : undefined}
      searchKeys={searchKeys}
      onSubmit={onSubmit}
      userCanEdit={!!userCanEditGroups && !isOrganisationGroup}
    />
  );
}

const mapStateToProps = (state, { groupId }) => {
  const {
    isUserGroup,
    isOrganisationGroup,
  } = getActiveGroupNodeInfo(state);
  const topLevelGroupActive = isTopLevelGroupActive(state);
  const userIsAdmin = !!hasAdminRightsInActiveGroup(state);
  const organisationIsStandard = !!isStandardOrganisation(state);
  const nearestOrganisationGroup = getNearestGroupOrganisationAncestor(state, groupId);
  const nearestOrganisationId = getNearestOrganisationAncestorId(state, groupId);
  return {
    isUserGroup,
    isOrganisationGroup,
    topLevelGroupActive,
    userCanViewGroups: userIsAdmin,
    userCanEditGroups: userIsAdmin && organisationIsStandard,
    organisationIsStandard,
    nearestOrganisationId,
    accessGroups: (getGroupAccess(state, groupId) || {}).groups,
    availableGroups: (nearestOrganisationGroup || {}).members,
  };
};
const mapDispatchToProps = {
  fetchGroup,
  fetchGroupAccess,
  addGroupAccessGroups,
  deleteGroupAccessGroups,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditGroupAccessGroups);
