
// Import polyfills for IE11
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';

import i18n from './i18n';
import { Provider } from 'react-redux';
import { store } from './store/configureStore';

import App from './App';

import ErrorBoundary from './components/ErrorBoundary';

// Delay rendering the app until i18n is fully initialised. Without this, best case we get warnings
// about trying to access the t function before it is initialised, but once it has fully initialised
// it doesn't matter because it can find the translations. This happens in the majority of the
// cases. In the worst case scenario, the call is always before the init and the users will always
// see the i18n key instead of the value e.g. "components.foo.bar" instead of "Foo Bar". This commit
// moves as much of the badly coded t calls inside hooks or HOCs as these will only be called once
// the app has rendered, given they are React concepts. There are a minority left that can't be
// moved (easily?). We shall see.
i18n.on('initialized', () => {
  ReactDOM.render(
    <ErrorBoundary>
      <Provider store={store}>
        <App />
      </Provider>
    </ErrorBoundary>,
    document.getElementById('root')
  );
});
