
import React, { Fragment, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { IoIosCopy } from 'react-icons/io';
import copy from 'copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import withNavigationUserProps from './withNavigationUserProps';
import './userPageLayout.scss';

import { InfoCard, InfoCardItem } from '../../../components/InfoCard';
import { addToast } from '../../../components/Toaster';

import  { fetchUserPreferences } from './../actions';

import {
  getUser,
  isAuthorised,
  getUserPreferenceUnitsOfMeasure,
  getUserOrganisations,
  getUserTypeOptions,
} from '../selectors';

function ContactLink({ type='email', children }) {
  return (
    <a
      className="contact-link--one-line"
      href={`${type === 'tel' ? 'tel' : 'mailto'}:${`${children}`.replace(/\s+/g, '')}`}
    >
      {children}
    </a>
  );
}

function UserSidebar({
  user = {},
  userIsAdmin,
  userUnitsOfMeasurePreference,
  organisations = [],
  fetchUserPreferences,
  userTypeOptions = {},
}) {
  const { t } = useTranslation();
  useEffect(() => {
    if (userIsAdmin) {
      fetchUserPreferences(user);
    }
  }, [userIsAdmin, user && user.id]);

  const copyEmail = useCallback(() => {
    const result = copy(user.email, { message: t('common.press-hash-key-to-copy') });
    if (result) {
      addToast({ variant: 'success', header: t('user.Copied_to_clipboard') });
    }
  }, [user.email]);

  if (!user || !user.id) {
    return null;
  }

  return (
    <Fragment>
      <h5 className="montserrat" style={{marginTop: "10px", marginBottom: "15px"}}>
        {user.name}
      </h5>
      <InfoCard>
        <InfoCardItem
          heading={(
            <Fragment>
              <span>{t('Email')}</span>
              {' '}
              <Button
                size="sm"
                variant="outline-secondary"
                className="btn-xs btn-muted info-card__inline-heading-button"
                onClick={copyEmail}
              >
                <IoIosCopy /> {t('Copy')}
              </Button>
            </Fragment>
          )}
        >
          <ContactLink>{user.email}</ContactLink>
        </InfoCardItem>
        <InfoCardItem heading={t('Phone')}>
          {`${user.country_code || ''} ${user.mobile_phone || ''}`.trim().length > 0 && (
            <ContactLink type="tel">{`${user.country_code || ''} ${user.mobile_phone || ''}`}</ContactLink>
          )}
        </InfoCardItem>
        <InfoCardItem heading={t('table.User_type')}>
          {userTypeOptions[user.user_type]?.display_name || user.user_type}
        </InfoCardItem>
        <InfoCardItem heading={t('user.Work_location')}>
          {user.location}
        </InfoCardItem>
        <InfoCardItem heading={t('Department')}>
          {user.department}
        </InfoCardItem>
        <InfoCardItem heading={t('Position')}>
          {user.position}
        </InfoCardItem>
        <InfoCardItem
          heading={t('Organisation', { count: 0 })}
          info={t('components.user.user-sidebar.organisations-user-log-in')}
        >
          <ul className="mt-1 mb-0">
            {(organisations || []).map(({ organisation_id, id, organisation_name, name }) => (
              <li key={organisation_id || id}>
                <Link to={`/organisations/${organisation_id || id}/`}>
                  {organisation_name || name}
                </Link>
              </li>
            ))}
          </ul>
        </InfoCardItem>
      </InfoCard>
      {userIsAdmin && (
        <InfoCard collapsible heading={t('Preferences')}>
          <InfoCardItem heading={t('components.user.common.multi-factor-authentication')}>
            {(user.mfa_enabled && t('enabled')) || t('not-enabled')}
          </InfoCardItem>
          <InfoCardItem heading={t('user.Units_of_Measure')}>
            {userUnitsOfMeasurePreference && (
              !userUnitsOfMeasurePreference.setByDefault
                ? userUnitsOfMeasurePreference.title
                : `${userUnitsOfMeasurePreference.title} (by\u00A0default)`
            )}
          </InfoCardItem>
          <InfoCardItem heading={t('Notifications')}>
            {user.receive_daily_email || user.receive_alert_email || user.receive_alert_sms ? (
              <ul className="mt-1 mb-0">
                {user.receive_daily_email && (<li>{t('user.Receive_daily_email')}</li>)}
                {user.receive_alert_email && (<li>{t('user.Receive_alert_email')}</li>)}
                {user.receive_alert_sms && (<li>{t('user.Receive_alert_SMS')}</li>)}
              </ul>
            ) : t('None')}
          </InfoCardItem>
        </InfoCard>
      )}
    </Fragment>
  );
}

const mapStateToProps = (state, { userId, userIsNew }) => {
  return userIsNew ? {} : {
    user: getUser(state, userId),
    userIsAdmin: isAuthorised(state, { minUserType: 'Admin' }),
    userUnitsOfMeasurePreference: getUserPreferenceUnitsOfMeasure(state, userId),
    organisations: getUserOrganisations(state, userId),
    userTypeOptions: getUserTypeOptions(state),
  };
};

export default withNavigationUserProps(
  connect(mapStateToProps, { fetchUserPreferences })(UserSidebar)
);
