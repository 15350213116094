import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchOrgAlarmDowntimeSaved } from '../../organisation/actions';
import { getOrgAlarmDowntimeSaved, getOrganisationId } from '../../organisation/selectors';
import {
  fetchAlarmDowntimeSaved,
  updateAlarmDowntimeSaved,
} from '../actions';
import {
  getAlarmDowntimeSaved,
  getAlarmDowntimeSavedState,
} from '../selectors';

function useAlarmDowntimeSaved(
  alarmId=null,
  { fetch = false } = {},
) {
  const dispatch = useDispatch();

  const organisationId =  useSelector(state => getOrganisationId(state));
  const alarmDowntimeSaved = useSelector(state => getAlarmDowntimeSaved(state, alarmId));
  const orgAlarmDowntimeSaved = useSelector(state => getOrgAlarmDowntimeSaved(state, organisationId));
  const { loading, error, lastFetch } = useSelector(state => getAlarmDowntimeSavedState(state, alarmId)) || {};

  const fetchDowntimeSaved = useCallback(() => {
    if (!alarmId) return;
    dispatch(fetchAlarmDowntimeSaved(alarmId));
  }, [alarmId, dispatch]);

  const fetchOrgDowntimeSaved = useCallback(() => {
    if (!organisationId) return;
    dispatch(fetchOrgAlarmDowntimeSaved({ id: organisationId }));
  }, [organisationId, dispatch]);

  const updateDowntimeSaved = useCallback(async (data) => {
    await dispatch(updateAlarmDowntimeSaved({ id: alarmId }, data));
    await dispatch(fetchAlarmDowntimeSaved(alarmId));
    await dispatch(fetchOrgAlarmDowntimeSaved({ id: organisationId }));
  }, [alarmId, organisationId, dispatch]);

  useEffect(() => {
    if (fetch && alarmId) {
      fetchDowntimeSaved();
    }
  }, [fetch, alarmId]);

  useEffect(() => {
    if (fetch && organisationId) {
      fetchOrgDowntimeSaved();
    }
  }, [fetch, organisationId]);

  return {
    loading,
    error,
    lastFetch,
    alarmDowntimeSaved,
    fetchDowntimeSaved,
    updateDowntimeSaved,
    fetchOrgDowntimeSaved,
    orgAlarmDowntimeSaved,
  };
}

export default useAlarmDowntimeSaved;
