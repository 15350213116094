import { Form } from 'react-bootstrap';
import { IoIosGlobe } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';

// en doesn't exist for import. Stupid design decision.
// It is US English. Annoying design decision.
// Begin quote
// By default, Moment.js comes with English (United States) locale strings. If you need other
// locales, you can load them into Moment.js for later use.
// End quote
// // https://momentjs.com/docs/#/i18n/changing-locale/

// en-US doesn't exist for import. moment will fallback from en-US to en. Stupid design decision.
// https://github.com/moment/moment/issues/3624.

// These locale imports actually set the moment locale! ARE YOU INSANE? Final one gets set so make
// sure it's en-AU or anyone without a config will get Spanish times.

// Core locales
import 'moment/locale/es';

// Extended locales
import 'moment/locale/en-ca';
import 'moment/locale/en-gb';
import 'moment/locale/en-ie';
import 'moment/locale/en-nz';
import 'moment/locale/es-mx';

// Default locale
// ALWAYS KEEP LAST! See above.
import 'moment/locale/en-au';

import i18n, { getStoredUserLocale } from '../i18n';

import {
  setAppLocale,
  setAppLocaleStale,
} from '../modules/user/actions';
import { getAppLocale } from '../modules/user/selectors';

// If a locale is stored, need to switch to that, or the user will end up on en-AU by default.
const storedLocale = getStoredUserLocale();
moment.locale(storedLocale.toLowerCase());

export const DEFAULT_LOCALE = 'en-AU';
export const DEFAULT_CURRENCY = 'AUD';

const languages = {
  // Edit this object to control the available languages. These map to public/locale/<language>.
  // Base file is AU English i.e. 'en', ignoring the fact that 'en-US' is considered the 'default'.
  // Locales only have their own custom spellings. i18next resolves from multiple places. The gist
  // being that is starts with the current, and then uses the fallback i.e. most specific to least
  // specific. With locale set to 'en-AU', i18next will find e.g. 'Organisation' in that file, then
  // will not find e.g. 'Archive', and will instead fallback to 'en' and find it there. This allows
  // for a single core file with only minor additional language files per locale.
  // Linguistically speaking there are minor differences between e.g. en-AU and en-GB and en-NZ, but
  // for practical purposes we'll assume British style and American style are the only two. If a
  // reason ever exists to be more specific, add e.g. en-NZ and update the mapping in languageMap.
  // https://www.i18next.com/principles/translation-resolution#resolution-order
  'en-AU': { shortName: 'English (AU)', longName: 'English (Australia)' },
  'en-CA': { shortName: 'English (CA)', longName: 'English (Canada)' },
  'en-GB': { shortName: 'English (GB)', longName: 'English (United Kingdom)' },
  'en-IE': { shortName: 'English (IE)', longName: 'English (Ireland)' },
  'en-NZ': { shortName: 'English (NZ)', longName: 'English (New Zealand)' },
  'en-US': { shortName: 'English (US)', longName: 'English (United States)' },

  'es-ES': { shortName: 'Español (ES)', longName: 'Español (España)' },
  'es-MX': { shortName: 'Español (MX)', longName: 'Español (México)' },
};

export function updateLocaleDisplay(locale) {
  // Simple function which sets the i18n language and the moment locale. No redux.
  if (locale) {
    i18n.changeLanguage(locale);
    // Lowercase is required or moment won't recognise it.
    // Sending an empty string to moment has no effect i.e. the locale stays the same.
    // en-US and es-ES don't exist for import. See src/i18n.js file for rant. Normally they would
    // fallback to en and es respectively and all works fine. When setting the dow below though,
    // moment makes a custom en-us or es-es with the dow settings and then because they don't exist,
    // the locale is borked.
    // Seriously, this library makes stupid design decisions. Many hours wasted on this weirdness.
    if (locale === 'en-US') locale = 'en';
    if (locale === 'es-ES') locale = 'es';
    moment.locale(locale.toLowerCase());
    moment.updateLocale(locale.toLowerCase(), { // Set up Monday as the first day of week for calendar.
      week: {
        dow: 1,
      }
    });
  }
}

export function LanguageOptions({ nameType }) {
  return (
    Object.keys(languages).map(lng => (
      <option key={lng} value={lng}>{languages[lng][nameType]}</option>
    ))
  );
}

export function LanguageSwitcher() {
  const dispatch = useDispatch();
  const anonymousLocale = useSelector(state => getAppLocale(state));
  const onChange = locale => {
    updateLocaleDisplay(locale);
    dispatch(setAppLocale(locale));
    dispatch(setAppLocaleStale(true));
  };
  return (
    <Form.Group controlId="select-language">
      <Form.Label style={{
        position: 'absolute',
        top: '5px',
        left: '5px'
      }}
      >
        <IoIosGlobe size="1.8em" style={{color: '#666'}} />
      </Form.Label>
      <Form.Control
        as="select"
        onChange={e => onChange(e.target.value)}
        value={anonymousLocale}
        style={{
          width: '180px',
          // Was paddingLeft: '2em', but this doesn't work on Safari. Tried lots of things. This is
          // an ancient problem in browser years.
          // This loses the down arrow on Opera, but I've only seen one user in BugSnag so it's not
          // worth complicating things. Button still works fine.
          // https://stackoverflow.com/questions/2966855/padding-is-not-working-in-safari-and-ie-in-select-list
          // https://stackoverflow.com/a/77899327/8590017
          textIndent: '25px',
        }}
      >
        <LanguageOptions nameType={'shortName'} />
      </Form.Control>
    </Form.Group>
  );
}
