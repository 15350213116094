import React from 'react';
import { IoIosWarning } from 'react-icons/io';
import { Trans, withTranslation } from 'react-i18next';

import { ContactLink, ExternalLink } from '../lib/utils';
import { productEmailAddress, productionDashboardUrl } from '../urls';

import AppNotificationBar from './AppNotificationBar';
import './betaBanner.scss';

const iconStyle = { verticalAlign: '-27%' };
const linkClass = 'click-here';

function BetaBanner({
  floating,
}) {

  return (
    <AppNotificationBar
      floating={floating}
      show={true}
      className={[
        'beta-banner',
        'beta-banner--danger',
        'beta-banner__notification-bar',
      ].join(' ')}
    >
      <IoIosWarning size="1.6em" style={iconStyle} />
      <Trans
        i18nKey="components.app-notification-bar.beta-banner"
        values={{ feedbackEmailAddress: productEmailAddress }}
        components={{
          feedbackMailto: <ContactLink
            to={productEmailAddress}
            className={linkClass}
          />,
          productionLink: <ExternalLink
            to={productionDashboardUrl}
            className={linkClass}
            title="Production Dashboard link"
          />,
        }}
      />
    </AppNotificationBar>
  );
}

export default withTranslation()(BetaBanner);
