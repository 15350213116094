import { useState, useCallback } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useDeviceAxisOptions from '../hooks/useDeviceAxisOptions';
import { addToast } from '../../../components/Toaster';

export function FMAxisOptionsForm(props) {
  const { t } = useTranslation();
  const { onCancel, onUpdateAxisOptions, axisOptions = {}, deviceId, /* unitPreference = 'metric' */ } = props;
  const { register, handleSubmit, setValue, watch } = useForm({
    mode: 'onChange',
    defaultValues: {
      minTemp: axisOptions.minTemp,
      maxTemp: axisOptions.maxTemp,
      minRms: axisOptions.minRms,
      maxRms: axisOptions.maxRms,
    }
  });
  const watchedFormValues = watch();
  const [saveSettings, setSaveSettings] = useState(false);

  const { updateAxisOptions } = useDeviceAxisOptions(deviceId);

  const onSubmit = useCallback(data => {
    for (const key in data) { // Delete the property that is not a number.
      if (data.hasOwnProperty(key) && isNaN(data[key])) {
        delete data[key];
      }
    }
    if(onUpdateAxisOptions instanceof Function) {
      onUpdateAxisOptions(data);
      if(saveSettings) {
        updateAxisOptions(data).then(() => {
          addToast({
            variant: 'success',
            header: t('components.equipment.axis-options-form.common.header')
          });
        });
      }
    }
  }, [updateAxisOptions, onUpdateAxisOptions, saveSettings]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col sm="6">
          <p>{t('components.equipment.axis-options-form.fitmachine.temperature-axis-options')}</p>
          <Form.Group as={Row}>
            <Form.Label column xs="4" sm="5" md="4">
              {t('components.equipment.axis-options-form.common.maximum')}
            </Form.Label>
            <Col xs="5" sm="4" md="5">
              <Form.Control
                type="number"
                step="1"
                // min="1"
                {...register("maxTemp", { valueAsNumber: true })}
              />
            </Col>
            <Col xs="auto">
              <Button
                disabled={isNaN(watchedFormValues.maxTemp)}
                onClick={() => setValue('maxTemp', undefined)}
              >{t('components.equipment.axis-options-form.common.auto')}</Button>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column xs="4" sm="5" md="4">
              {t('components.equipment.axis-options-form.common.minimum')}
            </Form.Label>
            <Col xs="5" sm="4" md="5">
              <Form.Control
                type="number"
                step="1"
                // min="0"
                {...register("minTemp", { valueAsNumber: true })}
              />
            </Col>
            <Col xs="auto">
              <Button
                disabled={isNaN(watchedFormValues.minTemp)}
                onClick={() => setValue('minTemp', undefined)}
              >{t('components.equipment.axis-options-form.common.auto')}</Button>
            </Col>
          </Form.Group>
        </Col>
        <Col sm="6">
          <p>{t('components.equipment.axis-options-form.fitmachine.rms-axis-options')}</p>
          <Form.Group as={Row}>
            <Form.Label column xs="4" sm="5" md="4">
              {t('components.equipment.axis-options-form.common.maximum')}
            </Form.Label>
            <Col xs="5" sm="4" md="5">
              <Form.Control
                type="number"
                step="0.01"
                min="0.01"
                {...register("maxRms", { valueAsNumber: true })}
              />
            </Col>
            <Col xs="auto">
              <Button
                disabled={isNaN(watchedFormValues.maxRms)}
                onClick={() => setValue('maxRms', undefined)}
              >{t('components.equipment.axis-options-form.common.auto')}</Button>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column xs="4" sm="5" md="4">
              {t('components.equipment.axis-options-form.common.minimum')}
            </Form.Label>
            <Col xs="5" sm="4" md="5">
              <Form.Control
                type="number"
                step="0.01"
                min="0"
                {...register("minRms", { valueAsNumber: true })}
              />
            </Col>
            <Col xs="auto">
              <Button
                disabled={isNaN(watchedFormValues.minRms)}
                onClick={() => setValue('minRms', undefined)}
              >{t('components.equipment.axis-options-form.common.auto')}</Button>
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Row className="small-gutters d-flex justify-content-end align-items-center">
        <Col sm="6">
          <Form.Group className="mb-0">
            <Form.Check>
              <Form.Check.Input
                id="axis-options-save"
                type="checkbox"
                checked={saveSettings}
                onChange={() => setSaveSettings(!saveSettings)}
                style={{ marginTop: '0.375em' }}
              />
              <Form.Check.Label htmlFor="axis-options-save">
                {t('components.equipment.axis-options-form.common.save-my-settings')}
              </Form.Check.Label>
            </Form.Check>
          </Form.Group>
        </Col>
        <Col sm="6">
          <Row className="small-gutters d-flex justify-content-end align-items-center">
            <Col xs="auto">
              <Button size="sm" type="submit">{t('common.save')}</Button>
            </Col>
            <Col xs="auto">
              <Button variant="outline-secondary" size="sm" type="button" onClick={() => {
                if(onCancel instanceof Function) onCancel();
              }}>
                {t('cancel')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
}

export function FPAxisOptionsForm(props) {
  const { t } = useTranslation();
  const { axisOptions = {}, deviceId, onUpdateAxisOptions, onCancel } = props;
  const { register, handleSubmit, setValue, watch } = useForm({
    mode: 'onChange',
    defaultValues: {
      minCurrent: axisOptions.minCurrent,
      maxCurrent: axisOptions.maxCurrent,
    }
  });
  const watchedFormValues = watch();
  const [ saveSettings, setSaveSettings ] = useState(false);
  const { updateAxisOptions } = useDeviceAxisOptions(deviceId);

  const onSubmit = useCallback(data => {
    for(const key in data) {
      if(data.hasOwnProperty(key) && isNaN(data[key])) {
        delete data[key];
      }
    }
    if(!isNaN(data.minCurrent) && !isNaN(data.maxCurrent) && data.minCurrent >= data.maxCurrent) {
      return addToast({
        header: t('components.equipment.axis-options-form.fitpower.maximum-greater-than-minimum')
      });
    }
    if(onUpdateAxisOptions instanceof Function) {
      onUpdateAxisOptions(data);
      if(saveSettings) {
        updateAxisOptions(data).then(() => {
          addToast({
            variant: 'success',
            header: t('components.equipment.axis-options-form.common.header')
          });
        });
      }
    }
  }, [updateAxisOptions, onUpdateAxisOptions, saveSettings]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col sm="6">
          <p>Axis options for current</p>
          <Form.Group as={Row}>
            <Form.Label column xs="4" sm="5" md="4">
              Maximum
            </Form.Label>
            <Col xs="5" sm="4" md="5">
              <Form.Control
                type="number"
                step="1"
                {...register("maxCurrent", { valueAsNumber: true })}
              />
            </Col>
            <Col xs="auto">
              <Button
                disabled={isNaN(watchedFormValues.maxCurrent)}
                onClick={() => setValue('maxCurrent', undefined)}
              >
                Auto
              </Button>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column xs="4" sm="5" md="4">
              Minimum
            </Form.Label>
            <Col xs="5" sm="4" md="5">
              <Form.Control
                type="number"
                step="1"
                {...register("minCurrent", { valueAsNumber: true })}
              />
            </Col>
            <Col xs="auto">
              <Button
                disabled={isNaN(watchedFormValues.minCurrent)}
                onClick={() => setValue('minCurrent', undefined)}
              >
                Auto
              </Button>
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Row className="small-gutters d-flex justify-content-end align-items-center">
        <Col sm="6">
          <Form.Group className="mb-0">
            <Form.Check>
              <Form.Check.Input
                id="axis-options-save"
                type="checkbox"
                checked={saveSettings}
                onChange={() => setSaveSettings(!saveSettings)}
                style={{ marginTop: '.375em' }}
              />
              <Form.Check.Label htmlFor="axis-options-save">
                Save my settings
              </Form.Check.Label>
            </Form.Check>
          </Form.Group>
        </Col>
        <Col sm="6">
          <Row className="small-gutters d-flex justify-content-end align-items-center">
            <Col xs="auto">
              <Button size="sm" type="submit">Save</Button>
            </Col>
            <Col xs="auto">
              <Button
                variant="outline-secondary"
                size="sm"
                type="button"
                onClick={() => {
                  if(onCancel instanceof Function) onCancel();
                }}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
}