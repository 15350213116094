
export const REQUEST_BEGIN = 'alarms/REQUEST_BEGIN';
export const REQUEST_FAIL = 'alarms/REQUEST_FAIL';

export const RECEIVE_ALARMS_LIST = 'alarms/RECEIVE_ALARMS_LIST';

export const REQUEST_ALARM = 'alarms/REQUEST_ALARM';
export const RECEIVE_ALARM = 'alarms/RECEIVE_ALARM';
export const ALARM_FAILURE = 'alarms/ALARM_FAILURE';

export const REQUEST_ALARM_EVENTS = 'alarms/REQUEST_ALARM_EVENTS';
export const RECEIVE_ALARM_EVENTS = 'alarms/RECEIVE_ALARM_EVENTS';
export const ALARM_EVENTS_FAILURE = 'alarms/ALARM_EVENTS_FAILURE';

export const REQUEST_ALARM_EVENTS_CREATE = 'alarms/REQUEST_ALARM_EVENTS_CREATE';
export const RECEIVE_ALARM_EVENTS_CREATE = 'alarms/RECEIVE_ALARM_EVENTS_CREATE';
export const ALARM_EVENTS_CREATE_FAILURE = 'alarms/ALARM_EVENTS_CREATE_FAILURE';

export const REQUEST_ALARM_EVENTS_UPDATE = 'alarms/REQUEST_ALARM_EVENTS_UPDATE';
export const RECEIVE_ALARM_EVENTS_UPDATE = 'alarms/RECEIVE_ALARM_EVENTS_UPDATE';
export const ALARM_EVENTS_UPDATE_FAILURE = 'alarms/ALARM_EVENTS_UPDATE_FAILURE';

export const REQUEST_ALARM_EVENTS_DELETE = 'alarms/REQUEST_ALARM_EVENTS_DELETE';
export const RECEIVE_ALARM_EVENTS_DELETE = 'alarms/RECEIVE_ALARM_EVENTS_DELETE';
export const ALARM_EVENTS_DELETE_FAILURE = 'alarms/ALARM_EVENTS_DELETE_FAILURE';

export const REQUEST_ALARM_IMAGES = 'alarms/REQUEST_ALARM_IMAGES';
export const RECEIVE_ALARM_IMAGES = 'alarms/RECEIVE_ALARM_IMAGES';
export const ALARM_IMAGES_FAILURE = 'alarms/ALARM_IMAGES_FAILURE';

export const REQUEST_ALARM_SOUNDS = 'alarms/REQUEST_ALARM_SOUNDS';
export const RECEIVE_ALARM_SOUNDS = 'alarms/RECEIVE_ALARM_SOUNDS';
export const ALARM_SOUNDS_FAILURE = 'alarms/ALARM_SOUNDS_FAILURE';

export const REQUEST_ALARM_VALID_REASONS = 'alarms/REQUEST_ALARM_VALID_REASONS';
export const RECEIVE_ALARM_VALID_REASONS = 'alarms/RECEIVE_ALARM_VALID_REASONS';
export const ALARM_VALID_REASONS_FAILURE = 'alarms/ALARM_VALID_REASONS_FAILURE';

export const REQUEST_ALARM_INVALID_REASONS = 'alarms/REQUEST_ALARM_INVALID_REASONS';
export const RECEIVE_ALARM_INVALID_REASONS = 'alarms/RECEIVE_ALARM_INVALID_REASONS';
export const ALARM_INVALID_REASONS_FAILURE = 'alarms/ALARM_INVALID_REASONS_FAILURE';

export const REQUEST_ALARM_CREATE = 'alarms/REQUEST_ALARM_CREATE';
export const RECEIVE_ALARM_CREATE = 'alarms/RECEIVE_ALARM_CREATE';
export const ALARM_CREATE_FAILURE = 'alarms/ALARM_CREATE_FAILURE';

export const REQUEST_ALARM_DELETE = 'alarms/REQUEST_ALARM_DELETE';
export const RECEIVE_ALARM_DELETE = 'alarms/RECEIVE_ALARM_DELETE';
export const ALARM_DELETE_FAILURE = 'alarms/ALARM_DELETE_FAILURE';

export const REQUEST_ALARM_IMPACT = 'alarms/REQUEST_ALARM_IMPACT';
export const RECEIVE_ALARM_IMPACT = 'alarms/RECEIVE_ALARM_IMPACT';
export const ALARM_IMPACT_FAILURE = 'alarms/ALARM_IMPACT_FAILURE';

export const RECEIVE_ADD_ALARM_IMPACT = 'alarms/RECEIVE_ADD_ALARM_IMPACT';
export const RECEIVE_UPDATE_ALARM_IMPACT = 'alarms/RECEIVE_UPDATE_ALARM_IMPACT';
export const RECEIVE_DELETE_ALARM_IMPACT = 'alarms/RECEIVE_DELETE_ALARM_IMPACT';
export const RECEIVE_ALARM_IMPACT_SUMMARY = 'alarms/RECEIVE_ALARM_IMPACT_SUMMARY';

export const REQUEST_ALARM_IMPACT_COST_CATEGORIES = 'alarms/REQUEST_ALARM_IMPACT_COST_CATEGORIES';
export const RECEIVE_ALARM_IMPACT_COST_CATEGORIES = 'alarms/RECEIVE_ALARM_IMPACT_COST_CATEGORIES';
export const ALARM_IMPACT_COST_CATEGORIES_FAILURE = 'alarms/ALARM_IMPACT_COST_CATEGORIES_FAILURE';

export const REQUEST_CHILD_ALARMS = 'alarms/REQUEST_CHILD_ALARMS';
export const RECEIVE_CHILD_ALARMS = 'alarms/RECEIVE_CHILD_ALARMS';
export const CHILD_ALARMS_FAILURE = 'alarms/CHILD_ALARMS_FAILURE';

export const REQUEST_ALARM_DOWNTIME_SAVED = 'alarms/REQUEST_ALARM_DOWNTIME_SAVED';
export const RECEIVE_ALARM_DOWNTIME_SAVED = 'alarms/RECEIVE_ALARM_DOWNTIME_SAVED';
export const ALARM_DOWNTIME_SAVED_FAILURE = 'alarms/ALARM_DOWNTIME_SAVED_FAILURE';

export const RECEIVE_UPDATE_ALARM_DOWNTIME_SAVED = 'alarms/RECEIVE_UPDATE_ALARM_DOWNTIME_SAVED';
export const UPDATE_ALARM_DOWNTIME_SAVED_FAILURE = 'alarms/UPDATE_ALARM_DOWNTIME_SAVED_FAILURE';
