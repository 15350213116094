import React, { Suspense, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18next';

import './app.scss';

import { PersistGate } from 'redux-persist/lib/integration/react';
import LoadingSpinner from './components/LoadingSpinner';
import { persistor } from './store/configureStore';
import { appLoad } from './modules/app/actions';

import AuthProvider from './lib/providers/AuthProvider';
import BugSnagProvider from './lib/providers/BugSnagProvider';

import { Router } from 'react-router-dom';
import Routes from './RoutesWithTracking';
import history from './history';

import { dropCache } from './lib/utils';
import Toaster from './components/Toaster';
import AppStatus from './components/AppStatus';

import {
  appLocaleStale,
  getAppLocale,
  getUser,
} from './modules/user/selectors';
import { setAppLocaleStale, submitUserPreferences } from './modules/user/actions';
import { getIntervalMilliSeconds } from './components/lib/utils';

const { REACT_APP_CHECK_TRANSLATIONS_INTERVAL_MINUTES = 5 } = process.env;
const INTERVAL = getIntervalMilliSeconds(REACT_APP_CHECK_TRANSLATIONS_INTERVAL_MINUTES);

function App(props, { appLoad }) {
  const { submitUserPreferences, setAppLocaleStale } = props;

  useEffect(() => {
    async function callUpdate() {
      await submitUserPreferences(props.user, {'preferences:locale': props.appLocale});
    }
    if (props.localeStale && props.user && props.user.id) {
      callUpdate().then(async () => {
        await setAppLocaleStale(false);
      });
    }
  }, [props.appLocale, props.localeStale, props.user]);

  const handleLogout = useCallback(() => {
    dropCache();
  }, [dropCache]);

  useEffect(() => {
    const interval = setInterval(() => {
      i18n.reloadResources(); // Reloads the translations, then next route change uses them
    }, INTERVAL);

    return () => clearInterval(interval);
  }, []);

  return (
    <AppStatus>
      <Suspense fallback={<LoadingSpinner />}>
        <PersistGate
          loading={<LoadingSpinner />}
          persistor={persistor}
          // triggered action after app is loaded (and persisted state is rehydrated)
          onBeforeLift={appLoad}
        >
          <BugSnagProvider>
            <AuthProvider onLogout={handleLogout}>
              <Router history={history}>
                <Routes />
              </Router>
              <Toaster />
            </AuthProvider>
          </BugSnagProvider>
        </PersistGate>
      </Suspense>
    </AppStatus>
  );
}

const mapStateToProps = (state, { userId }) => {
  return {
    user: getUser(state, userId),
    appLocale: getAppLocale(state),
    localeStale: appLocaleStale(state),
  };
};

const mapDispatchToProps = { appLoad, submitUserPreferences, setAppLocaleStale };

export default connect(mapStateToProps, mapDispatchToProps)(App);
