import { Dispatch } from 'redux';
import { t } from 'i18next';

import { CALL_API } from '../../lib/apiMiddleware';
import { Gateway } from './types';
import * as ACTION_TYPES from './types/ActionTypes';
import * as GatewayAction from './types/Action';

export function requestGatewayList(): GatewayAction.RequestGatewayListAction {
  return {
    type: ACTION_TYPES.REQUEST_GATEWAY_LIST,
  };
}

export function failureGatewayList(): GatewayAction.FailureGatewayListAction {
  return {
    type: ACTION_TYPES.GATEWAY_LIST_FAILURE,
  };
}

export function receiveGatewayList(): GatewayAction.ReceiveGatewayListAction {
  return {
    type: ACTION_TYPES.RECEIVE_GATEWAY_LIST,
  };
}

export function fetchGateways(): (dispatch: Dispatch<GatewayAction.FetchGatewaysAction>) => GatewayAction.FetchGatewaysAction {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      // todo: enforce a certain filter cut-off to be in line with
      // visualisations placed on the Map
      endpoint: '/gateways',
      params: {
        'age_in_days': 30,
      },
      requestAction: requestGatewayList(),
      successAction: receiveGatewayList(),
      errorAction: failureGatewayList()
    });
  };
}

export function requestGateway(gateway: Pick<Gateway, "id">): GatewayAction.RequestGatewayAction {
  return {
    type: ACTION_TYPES.REQUEST_GATEWAY,
    gateway,
  };
}

export function receiveGateway(gateway: Pick<Gateway, "id">): GatewayAction.ReceiveGatewayAction {
  return {
    type: ACTION_TYPES.RECEIVE_GATEWAY,
    gateway,
  };
}

export function failureGateway(gateway: Pick<Gateway, "id">): GatewayAction.FailureGatewayAction {
  return {
    type: ACTION_TYPES.GATEWAY_FAILURE,
    gateway,
  };
}
export function fetchGateway(gateway: Pick<Gateway, "id">): (dispatch: Dispatch<GatewayAction.FetchGatewayAction>) => GatewayAction.FetchGatewayAction {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/gateways/${gateway.id}`,
      requestAction: requestGateway(gateway),
      successAction: receiveGateway(gateway),
      errorAction: failureGateway(gateway)
    });
  };
}

export function receiveUpdateGateway(id: number, gateway: Partial<Gateway>): GatewayAction.ReceiveUpdateGatewayAction {
  return {
    type: ACTION_TYPES.RECEIVE_UPDATE_GATEWAY,
    id,
    gateway,
  };
}

export function updateGateway(id: number, gateway: Partial<Gateway>): (dispatch: Dispatch<GatewayAction.UpdateGatewayAction>) => GatewayAction.UpdateGatewayAction {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'put',
      endpoint: `/gateways/${id}`,
      data: gateway,
      requestAction: requestGateway({id}),
      successAction: receiveUpdateGateway(id, gateway),
      errorAction: failureGateway({id}),
      successToast: t('toasts.access-point-edited')
    });
  };
}
