import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { t } from 'i18next';

import './appStatus.scss';

import { checkAppVersionStatus } from '../modules/app/actions';

import { addToast } from '../components/Toaster';
import { usePoll } from '../hooks/usePoll';
import { getIntervalMilliSeconds } from './lib/utils';

const { REACT_APP_CHECK_VERSION_INTERVAL_MINUTES = 5 } = process.env;
const appCheckPeriodMs = getIntervalMilliSeconds(REACT_APP_CHECK_VERSION_INTERVAL_MINUTES);

function AppStatus({ children, checkAppVersionStatus }) {

  const [deprecated, setDeprecated] = useState(false);

  const checkAppStatus = async () => {

    // fetch platform answer (may be 404 meaning no status entry found for this version)
    const result = await checkAppVersionStatus()
      .then(result => result)
      .catch(error => (error && error.response) || { status: 404 });

    // found warning to display
    if (result.status === 200) {
      addToast({
        variant: 'default',
        header: (
          <div className="text-center">
            <p className="my-1">{t('toasts.new-version-available')}</p>
            <p className="my-1">{t('toasts.please-refresh')}</p>
            <Button className="my-2" onClick={() => {
              // reload the current page
              window.location.reload();
              // return false so that onClick doesn't continue propagating the event
              // where this reload may end up being cancelled by the presence of any other action
              return false;
            }}>
              Update
            </Button>
          </div>
        ),
        timeout: false,
        dismissable: false,
      });
      // if this version is deprecated,
      // add a wall to block the user from interacting with the dashboard
      if (result && result.data && result.data.deprecated) {
        setDeprecated(true);
      }
    }
  };

  usePoll(checkAppStatus, [], { interval: appCheckPeriodMs });

  return (
    <Fragment>
      {children}
      {deprecated && (
        <div className="app-blocker" />
      )}
    </Fragment>
  );
}

const mapDispatchToProps = { checkAppVersionStatus };

export default connect(null, mapDispatchToProps)(AppStatus);
