import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';

import { login, setAppLocale, setAppLocaleStale } from '../actions';

import { Form, Button } from 'react-bootstrap';
import LoadingSpinner from '../../../components/LoadingSpinner';

import RootUnauthenticated from '../../../components/RootUnauthenticated';

import { ExternalLink, getFormValues } from '../../../lib/utils';

import { CopyrightText } from '../../../components/Footer';
import { addToast } from '../../../components/Toaster';
import { getAppLocale, getUserLastLogin } from '../selectors';
import { getQueryParamFromProps } from '../../selectors';
import { privacyUrl } from '../../../urls';

class Login extends Component {

  state = {
    loggingIn: false,
  };

  checkAllFormFields = (email='', password='', organisation='') => {
    const { t } = this.props;
    // Check for email field
    if (email.length === 0) {
      addToast({
        variant: 'danger',
        header: t('user.Please_enter_valid_email_address', 'Please enter a valid email address'),
      });
      return false;
    }

    // Check for password Field
    if (password.length === 0) {
      addToast({
        variant: 'danger',
        header: t('user.Please_enter_valid_password', 'Please enter a valid password'),
      });
      return false;
    }

    // Check for Organisation field
    if (organisation.length === 0) {
      addToast({
        variant: 'danger',
        header: t('user.Please_enter_valid_organisation', 'Please enter a valid organisation'),
      });
      return false;
    }

    return true;
  };

  handleSubmit = async (e) => {
    const { email, password, organisation } = getFormValues(e);
    const { login, appLocale, setAppLocale, setAppLocaleStale } = this.props;

    try {
      if (this.checkAllFormFields(email, password, organisation)) {
        this.setState({ loggingIn: true });
        await login(email, password, organisation);
        setAppLocale(appLocale);
        setAppLocaleStale(true);
      }
    }
    catch(e) {
      this.setState({ loggingIn: false });
    }
  };

  render() {
    const {
      lastLogin = {},
      queryEmail,
      queryOrganisation,
      t,
    } = this.props;
    const { loggingIn } = this.state;

    return (
      <RootUnauthenticated title={t('user.Sign_in_to_MachineCloud', 'Sign in to MachineCloud')}>
        <Form className="form-floating-labels" onSubmit={this.handleSubmit}>

          <Form.Group>
            {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
            <Form.Control disabled={loggingIn} type="email" name="email" defaultValue={lastLogin.email || queryEmail || ''} id="inputEmail" placeholder="Email address" autoFocus autoComplete="email" />
            <Form.Label htmlFor="inputEmail">{t('user.Email_address', 'Email address')}</Form.Label>
          </Form.Group>

          <Form.Group>
            <Form.Control disabled={loggingIn} type="password" name="password" id="inputPassword" placeholder="Password" autoComplete="current-password" />
            <Form.Label htmlFor="inputPassword">{t('user.Password', 'Password')}</Form.Label>
          </Form.Group>

          <Form.Group>
            <Form.Control disabled={loggingIn} type="text" name="organisation" defaultValue={lastLogin.organisation || queryOrganisation || ''} id="inputOrganisation" placeholder="Organisation (e.g. org.dash.movus)" />
            <Form.Label htmlFor="inputOrganisation">{t('user.Organisation_and_eg', 'Organisation (e.g. org.dash.movus)')}</Form.Label>
          </Form.Group>
          <p className="text-muted">
            <small>
              <Trans
                defaults={"Clicking \"Sign in\" means you accept our <privacyLink>Privacy Policy</privacyLink>."}
                i18nKey="user.Clicking_sign_in_to_accept"
                components={{
                  privacyLink: <ExternalLink
                    to={privacyUrl}
                    title="Privacy policy link"
                  />,
                }}
              />
            </small>
          </p>
          <Button size="lg" variant="info" block type="submit" disabled={loggingIn}>{
            loggingIn
              ? <LoadingSpinner size="1.5" />
              : t('user.Sign_in', 'Sign in')
          }</Button>
        </Form>
        <br/>
        <Link to="/password/forgot">{t('user.Forgot_password', 'Forgot Password')}?</Link>
        <br/><br/>
        <p className="text-muted">
          <small>
            <CopyrightText />
          </small>
        </p>
      </RootUnauthenticated>
    );
  }
}

const mapStateToProps = (state, props) => ({
  lastLogin: getUserLastLogin(state),
  queryEmail: getQueryParamFromProps(props, 'email'),
  queryOrganisation: getQueryParamFromProps(props, 'org') || getQueryParamFromProps(props, 'organisation'),
  appLocale: getAppLocale(state),
});
const mapDispatchToProps = { login, setAppLocale, setAppLocaleStale };

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Login));
