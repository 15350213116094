import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import FormModal from '../../../components/FormModal';
import { sendConfirmOTP } from '../actions';
import { getOtpToken } from '../selectors';

export const otpPattern = "^[0-9]{6}$";

function MFAManager(props) {

  const {
    otpToken,
    sendConfirmOTP,
  } = props;

  const { t } = useTranslation();
  const [valid, setValid] = useState(false);
  const [otpCode, setOtpCode] = useState('');

  const handleChange = e => {
    const input = e.target.value;
    setOtpCode(input);
    const otpRegex = new RegExp(otpPattern);
    otpRegex.test(input) ? setValid(true) : setValid(false);
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (valid) {
      sendConfirmOTP(otpCode, otpToken)
        .catch(() => {
          setOtpCode('');
          setValid(false);
        });
    }
  };

  return (
    <FormModal
      header={t('components.user.mfa-manager.header')}
      body={t('components.user.mfa-manager.body')}
      confirmText={t('Confirm')}
      className="equipment-list-edit-columns-modal"
      valid={valid}
      form={
        <Form className="position-relative" onSubmit={handleSubmit}>
          <Form.Group as={Row} controlId="form__edit_user--name">
            <Form.Label column sm="2" xs="4">{t('components.user.mfa-manager.otp')}</Form.Label>
            <Col sm="8" xs="6">
              <Form.Control
                type="text"
                name="otpCode"
                required
                value={otpCode}
                onChange={handleChange}
              />
            </Col>
          </Form.Group>
        </Form>
      }
    >
      <Button
        size="sm"
        variant="success"
        type="submit"
      >
        {t('components.user.mfa-manager.enter-confirmation-otp')}
      </Button>
    </FormModal>
  );
}

const mapStateToProps = (state) => {
  return {
    otpToken: getOtpToken(state),
  };
};

const mapDispatchToProps = {
  sendConfirmOTP,
};

export default connect(mapStateToProps, mapDispatchToProps)(MFAManager);
